import './GlobalSearchInput.scss';
import React from 'react';
import clsx from 'clsx';
import { IHistorySearchItem } from '@models/search/IHistorySearchItem';
import Search from '@atoms/Search';
import { RouteNames } from '@/router/router.types';
import { useLocation, useNavigate, useSearchParams } from 'react-router';
import { useActions } from '@hooks/useActions';

export interface IGlobalSearchInputProps {}

const GlobalSearchInput: React.FC<IGlobalSearchInputProps> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { searchDocumentsGlobal } = useActions();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchText = searchParams.get('text') ?? '';

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const searchString = e.currentTarget.value.trim();
            if (searchString) {
                doSearchByString(searchString);
            }
        }
    };

    const doSearchByString = (searchString: string) => {
        if (location.pathname !== RouteNames.SEARCH) {
            navigate({ pathname: RouteNames.SEARCH, search: `text=${searchString}` });
        } else {
            setSearchParams({ text: searchString });
            searchDocumentsGlobal({ text: searchString, requireTotalCount: true });
        }
    };

    const onHistorySearchSelected = (historyItem: IHistorySearchItem) => {
        doSearchByString(historyItem.text);
    };

    return (
        <div className={clsx('global-search')}>
            <Search
                width={350}
                onKeyUp={onKeyUp}
                value={searchText}
                onHistorySearchSelected={onHistorySearchSelected}
            />
        </div>
    );
};

export default GlobalSearchInput;
