import '../ActivityActions.scss';
import React, { useState } from 'react';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import Textarea from '@atoms/Textarea';
import { ITakeToWorkProps } from './ITakeToWorkProps';

export const TakeToWorkModal: React.FC<ITakeToWorkProps> = (props: ITakeToWorkProps) => {
    const [comment, setComment] = useState<string>('');
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const onSubmit = () => {
        const service = new ActivityExecutionService();
        setLoading(true);

        service
            .takeToWork(props.actionKey, props.objId, comment)
            .then((x) => {
                if (x.status == 200 && props?.completeHandler) props.completeHandler(true);
            })
            .catch((reason) => {
                setErrorText(reason);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>
                        <span>Взять в работу</span>
                    </div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props?.completeHandler) props?.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        onClick={() => {
                            onSubmit();
                        }}
                        size="s"
                        aria-label={props.okButtonText}
                        disabled={loading}
                    >
                        {props.okButtonText}
                    </Button>
                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                        disabled={loading}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Preloader size="m" position="static" />
            ) : (
                <div className="actForm">
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}
                    {props.hideComment ? (
                        <></>
                    ) : (
                        <Textarea
                            className="action-modal-dlg-input"
                            placeholder="Комментарий"
                            rows={6}
                            name="commentText"
                            onChange={(e: any) => {
                                setComment(e.target.value);
                            }}
                        />
                    )}
                </div>
            )}
        </Modal>
    );
};
