import DataGrid, {
    Column,
    Editing,
    FilterRow,
    Grouping,
    GroupPanel,
    Item,
    LoadPanel,
    MasterDetail,
    Pager,
    Paging,
    RemoteOperations,
    Scrolling,
    Selection,
    Sorting,
    Toolbar,
} from 'devextreme-react/data-grid';
import React, { JSX, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IDataGridColumnDescription } from '@models/response/IGridDescriptionResponse';
import { IDataGridDescriptionResponse } from '@models/response/IDataGridDescriptionResponse';
import DevExpressDataGrid, { IDevExpressDataGridOptions } from './DevExpressDataGrid';
import { IDataGridService } from '@services/IDataGridService';
import Button from '@atoms/Button';
import { MdDownload } from 'react-icons/md';
import { Template } from 'devextreme-react/core/template';
import '../styles/iproc-scheme.css';
import './DevExpressDataGridDynamic.scss';
import { IGridStateSettings } from '@models/response/IGridStateSettings';
import { IDataGridStateService } from '@services/IDataGridStateService';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import {
    applyStateSettings,
    calculateFilterExpression,
    getColumnDataTypeByFieldDataType,
    getColumnFilterOperationsByColumnDataType,
    getOriginalState,
    getStateSettings,
    onCellHoverChanged,
    setDataValueAttr,
    updateModeOff,
    updateModeOn,
} from '@utils/dataGridUtils';
import ActionButton from '@atoms/DevExpress/Buttons/ActionButton/ActionButton';
import { IActionService } from '@services/actions/IActionService';
import { IBaseAction } from '@models/actions/IBaseAction';
import { getDocIdFromLinkedDocId, simulateMouseClick } from '@utils/helpers';
import GroupActionButton, { IGroupActionButtonRefActions } from './GroupActionButton';
import DisplayField from '@atoms/DisplayField/DisplayField';
import { Link } from 'react-router';
import Tooltip from '@atoms/Tooltip';
import dxDataGrid, {
    CellPreparedEvent,
    OptionChangedEvent,
    RowPreparedEvent,
    SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import DataSource from 'devextreme/data/data_source';
import DocumentFormDetails from '@atoms/DevExpress/DataGrid/Details/DocumentFormDetails/DocumentFormDetails';
import GridDetails from '@atoms/DevExpress/DataGrid/Details/GridDetails/GridDetails';
import StateModal from '@organisms/StateModal/StateModal';
import { exportGridToExcel } from '@utils/devextreme-react/dataGridUtils';
import { useLocalStorage } from 'usehooks-ts';
import { GridWordWrapMode, IGridSettings } from '@models/dataGrid/IGridSettings';
import GridSettingsButton, {
    IMenuSettingsRefActions,
} from '@atoms/DevExpress/Buttons/GridSettingsButton/GridSettingsButton';
import PredefinedFilters from '@molecules/PredefinedFilters/PredefinedFilters';
import { IPredefinedFilter } from '@models/response/IPredefinedFiltersItems';
import { ConfigService } from '@/configuration/services/configService';
import { useUserScripts } from '@hooks/useUserScripts';
import ColumnChooser, { IColumnChooser } from '@atoms/ColumnChooser/ColumnChooser';
import { TabsContext } from '@/context/Contexts';
import { ValueType } from '@/types/ValueType';

export interface IDevExpressDataGridDynamicOptions extends Omit<IDevExpressDataGridOptions, 'ref'> {
    ref?: React.Ref<IDxDataGridDynamicHandle>;
    service: IDataGridService;
    onRowClick?: (e: any) => void;
    onRowPrepared?: (e: any) => void;
    onContextMenuPreparing?: (e: any) => void;
    dataKey: string;
    gridKey: string;
    stateService: IDataGridStateService;
    actionService?: IActionService<IBaseAction>;
    toolbarItems?: JSX.Element[];
    customTemplates?: JSX.Element[];
    linkPrefix?: string;
    isExternalDescription?: boolean;
    externalDescription?: IDataGridDescriptionResponse;
    predefinedFiltersEnabled?: boolean;
    selectedPredefinedFilter?: IPredefinedFilter;
    onChangeSelectedPredefinedFilter?: (filter: IPredefinedFilter | undefined) => void;
    loadPredefinedFilters?: () => IPredefinedFilter[] | undefined;
}

export type IDxDataGridDynamicHandle = {
    refresh: () => void;
    getGridRef: () => React.Ref<DataGrid<any, any>>;
};

const allowedPageSizes: (number | 'all' | 'auto')[] = [5, 10, 20, 'all'];

const DevExpressDataGridDynamic: React.FC<IDevExpressDataGridDynamicOptions> = ({
    ref,
    service,
    onRowClick,
    onRowPrepared: onRowPreparedProp,
    onContextMenuPreparing,
    dataKey,
    gridKey,
    stateService,
    actionService,
    toolbarItems = [],
    customTemplates,
    linkPrefix,
    isExternalDescription = false,
    externalDescription = undefined,
    predefinedFiltersEnabled = false,
    selectedPredefinedFilter,
    onChangeSelectedPredefinedFilter,
    loadPredefinedFilters,
    ...props
}) => {
    const config = ConfigService.get();
    const applyFilterOnClick = config.application.applyGridFilterOnClick ?? false;
    const scrollByContent = config.application.scrollByContentInTables ?? false;

    const [loadedGridDescription, setLoadedGridDescription] = useState<IDataGridDescriptionResponse>();
    const [currentGridDescription, setCurrentGridDescription] = useState<IDataGridDescriptionResponse>();
    const [showStateModal, setShowStateModal] = useState<boolean>(false);
    const [stateData, setStateData] = useState<IGridStateSettings[]>();
    const activated = React.useRef(false);
    const controlRef = useRef<IGroupActionButtonRefActions>(null);
    const [localLoadOptions, setLocalLoadOptions] = useState<any>();
    const gridRef = useRef<DataGrid>(null);
    const columnChooserRef = useRef<IColumnChooser>(null);
    const isGridUpdating = useRef<boolean>(false);

    const grid = useCallback(() => {
        return gridRef.current?.instance;
    }, []);

    const hasDetailsGrid = useMemo(() => {
        return (
            currentGridDescription?.options.isMaster &&
            (!!currentGridDescription.detailsForm || !!currentGridDescription.detailsGrid)
        );
    }, [
        currentGridDescription?.detailsForm,
        currentGridDescription?.detailsGrid,
        currentGridDescription?.options?.isMaster,
    ]);

    const resetState = useCallback(() => {
        grid()?.state(null);
    }, [grid]);

    const refreshGrid = useCallback(() => {
        grid()?.refresh();
    }, [grid]);

    // Принудительно обновляем размеры таблиц при смене вкладок
    const tabsContext = useContext(TabsContext);
    useEffect(() => {
        grid()?.updateDimensions();
    }, [tabsContext?.activeTab]);

    useImperativeHandle(ref, () => ({
        refresh() {
            grid()?.refresh();
        },
        getGridRef() {
            return gridRef;
        },
    }));

    const buildDataSource = useCallback(
        (keyGetter: () => string, gridGetter: () => dxDataGrid | undefined, editing: () => boolean) => {
            return new DataSource<any, string>({
                key: keyGetter(),
                ...(editing() && {
                    update: (key: any, values: any) => {
                        return service.putData({ key: key, values: values, runHook: false }).then((res) => {
                            return res.data;
                        });
                    },
                }),
                load(options: any) {
                    setLocalLoadOptions(options);

                    let columnsIndexes: string[] = [];
                    gridGetter()
                        ?.getVisibleColumns()
                        ?.forEach((column) => {
                            if (
                                column?.visibleIndex == null ||
                                column.dataField == 'actionColumn' ||
                                column.dataField == 'emptyColumn' ||
                                column.type == 'selection'
                            ) {
                                return;
                            }

                            columnsIndexes.push(((column as any).index + 1).toString());
                        });

                    if (columnsIndexes.length === 0) return [];

                    let loadOptions = {
                        options: options,
                        fields: columnsIndexes.join(','),
                    };

                    return service.fetchData(dataKey, loadOptions).then((res) => {
                        return res.data;
                    });
                },
                requireTotalCount: true,
            });
        },
        [dataKey, service],
    );

    const createDataSource = useCallback(() => {
        if (loadedGridDescription) {
            grid()?.option(
                'dataSource',
                buildDataSource(
                    () => loadedGridDescription.dataSource.store.key,
                    () => grid(),
                    () => loadedGridDescription.columns.some((x) => x.allowEdit === true),
                ),
            );
        }
    }, [loadedGridDescription]);

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    /**
     * Сброс по изменению темплейта
     */
    useEffect(() => {
        if (dataKey) {
            setLoadedGridDescription(undefined);
            setStateData(undefined);
        }
    }, [dataKey]);

    // -------------------------------------------------------------------------------------------------------------------
    // Пользовательские настройки
    // -------------------------------------------------------------------------------------------------------------------
    const userSettingsRef = useRef<IMenuSettingsRefActions>(null);
    const needReopenMenu = useRef<boolean>(false);

    const [userSettings, setUserSettings] = useLocalStorage<IGridSettings>(`grid.settings.${gridKey}`, {
        wordWrapMode: GridWordWrapMode.Wrap,
    });

    useEffect(() => {
        grid()?.repaint();

        if (needReopenMenu.current) {
            // Задержка для открытия меню после завершения рендера
            setTimeout(() => {
                userSettingsRef.current?.openMenu();
                needReopenMenu.current = false;
            }, 0);
        }
    }, [userSettings.wordWrapMode]);

    /*const isMenuOpen = useTypedSelector((state) => state.mainLayout.isMenuOpen);
        useEffect(() => {
            // Перерисовываем грид при открытии/закрытии бокового меню
            if (userSettings.wordWrapMode !== GridWordWrapMode.NoWrap) {
                setTimeout(() => {
                    grid()?.repaint();
                }, 300);
            }
        }, [isMenuOpen]);*/

    // -------------------------------------------------------------------------------------------------------------------

    /**
     * Получение состояний
     */
    useEffect(() => {
        if (dataKey) {
            stateService.fetchState(dataKey).then((response) => {
                if (activated.current) {
                    setStateData(response.data.length > 0 ? response.data : []);
                }
            });
        }
    }, [dataKey]);

    /**
     * Получение схемы грида (внешний обработчик)
     */
    useEffect(() => {
        if (isExternalDescription) {
            if (externalDescription) {
                setLoadedGridDescription(externalDescription);
            }
        }
    }, [externalDescription]);

    /**
     * Получение схемы грида (внутренний обработчик)
     */
    useEffect(() => {
        if (!isExternalDescription) {
            if (dataKey) {
                service.fetchScheme(dataKey).then((res) => {
                    if (activated.current) {
                        setLoadedGridDescription(res.data);
                    }
                });
            }
        }
    }, [dataKey]);

    useEffect(() => {
        if (loadedGridDescription) {
            setCurrentGridDescription(loadedGridDescription);
        }
    }, [loadedGridDescription]);

    /**
     * TODO Fix W1005
     */
    useEffect(() => {
        if (loadedGridDescription && currentGridDescription) {
            updateModeOn(grid(), isGridUpdating);
            resetState();
            createDataSource();
            //updateModeOff(grid(), isGridUpdating);
        }
    }, [loadedGridDescription, currentGridDescription]);

    /**
     * Обновление пользовательского состояния при загрузке и при выборе из модального окна
     */
    useEffect(() => {
        if (loadedGridDescription && currentGridDescription && stateData) {
            updateModeOn(grid(), isGridUpdating);
            applyStateSettings(grid(), getStateSettings(stateData)).finally(() => {
                updateModeOff(grid(), isGridUpdating);
            });
        }
    }, [loadedGridDescription, currentGridDescription, stateData]);

    const onClickRefresh = useCallback(() => {
        updateModeOn(grid(), isGridUpdating);
        applyStateSettings(grid(), getOriginalState(loadedGridDescription)).finally(() => {
            updateModeOff(grid(), isGridUpdating);
        });
    }, [grid, loadedGridDescription]);

    const onClickStateChooser = useCallback(() => {
        setShowStateModal(true);
    }, []);

    const onClickColumnChooser = useCallback(() => {
        columnChooserRef.current?.open(gridRef);
    }, [grid]);

    const onClickExport = useCallback(async () => {
        let columns: IGridExportSettings[] = [];

        gridRef.current?.instance.getVisibleColumns()?.forEach((column) => {
            if (
                column?.visibleIndex == null ||
                column.dataField == 'actionColumn' ||
                column.dataField == 'emptyColumn' ||
                column.type == 'selection'
            )
                return;

            if ((column?.visibleIndex ?? -1) >= 0) {
                columns.push({
                    dataField: column.dataField,
                    dataType: column.dataType,
                    name: column.caption,
                    visible: column.visible,
                    visibleIndex: column.visibleIndex,
                    width: column.width,
                });
            }
        });

        const options = localLoadOptions ? localLoadOptions : gridRef.current?.instance.getDataSource().loadOptions();
        gridRef.current?.instance.beginCustomLoading('Экспортировать данные');
        await service.exportData(dataKey, options, columns);
        gridRef.current?.instance.endCustomLoading();
    }, [dataKey, localLoadOptions, service]);

    const onEditorPreparing = useCallback((e: any) => {
        if (applyFilterOnClick && e.parentType === 'filterRow') {
            e.editorOptions.onEnterKey = function () {
                // применение фильтра по нажатию Enter
                simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
            };
        }
    }, []);

    // -------------------------------------------------------------------------------------------------------------------
    // Пользовательские скрипты
    // -------------------------------------------------------------------------------------------------------------------

    const { onRowPreparedScript, onCellPreparedScript } = useUserScripts(
        currentGridDescription?.options.onRowPrepared,
        currentGridDescription?.options.onCellPrepared,
    );

    const onRowPrepared = useCallback(
        async (row: RowPreparedEvent<any, string>) => {
            if (row.rowType === 'data' && onRowPreparedScript) {
                try {
                    eval(onRowPreparedScript);
                } catch (error) {
                    console.log('onRowPrepared. Ошибка выполнения скрипта. Подробности ниже.');
                    console.error(error);
                }
            }
            onRowPreparedProp && onRowPreparedProp(row);
        },
        [onRowPreparedProp, onRowPreparedScript],
    );

    const onCellPrepared = useCallback(
        async (cell: CellPreparedEvent<any, string>) => {
            if (cell.rowType === 'data') {
                setDataValueAttr(cell);
            }

            if (cell.rowType === 'data' && onCellPreparedScript) {
                try {
                    eval(onCellPreparedScript);
                } catch (error) {
                    console.log('onCellPrepared. Ошибка выполнения скрипта. Подробности ниже.');
                    console.error(error);
                }
            }
        },
        [onCellPreparedScript],
    );

    // -------------------------------------------------------------------------------------------------------------------

    const onSelectionChanged = useCallback((e: SelectionChangedEvent<any, string>) => {
        let keys = e.selectedRowKeys;
        let data = e.selectedRowsData;
        if (keys.length > 0) {
            controlRef.current?.setObjData(keys.join(','), data);
        } else {
            controlRef.current?.setObjData('', data);
        }
    }, []);

    const onOptionChanged = useCallback((e: OptionChangedEvent<any, string>) => {
        /**
         * Обновление данных грида при добавлении столбцов
         */
        if (e.name === 'columns') {
            if (e.fullName.endsWith('.visible') && e.value === true) {
                e.component.refresh();
            }
        }
    }, []);

    const onGroupActionComplete = useCallback(() => {
        refreshGrid();
        grid()?.deselectAll();
    }, [grid, refreshGrid]);

    const getGridColumnFromDescription = useCallback(
        (description: IDataGridColumnDescription, key: string): JSX.Element => {
            return (
                <Column
                    key={key}
                    /* Настройка width ломает настройку грида columnResizingMode="widget" */
                    width={description.width ? description.width : undefined}
                    minWidth={description.width.toString().endsWith('vw') ? 5 : description.minWidth}
                    allowFiltering={description.allowFiltering}
                    caption={description.header}
                    dataField={description.field}
                    dataType={getColumnDataTypeByFieldDataType(description.dataType)}
                    alignment={description.alignment}
                    showInColumnChooser={description.showInColumnChooser}
                    visible={!description.defaultHide}
                    allowGrouping={description.allowGrouping}
                    allowEditing={description.allowEdit}
                    sortIndex={description.sortIndex ?? undefined}
                    sortOrder={description.sortOrder}
                    //colIndexId={ description.colIndexId}
                    //allowFiltering={description.dataField !== "actionColumn"}
                    allowReordering={!(description.field === 'actionColumn' || description.field === 'emptyColumn')}
                    allowSorting={!(description.field === 'actionColumn' || description.field === 'emptyColumn')}
                    filterOperations={getColumnFilterOperationsByColumnDataType(
                        getColumnDataTypeByFieldDataType(description.dataType),
                    )}
                    // фиксируем колонку действий, только если нет детализации строк, иначе ломается верстка таблицы при разворачивании подчиненных строк
                    fixed={description.field === 'actionColumn' && !hasDetailsGrid}
                    fixedPosition={'right'}
                    calculateFilterExpression={calculateFilterExpression}
                    encodeHtml={true}
                    cssClass={description.cssClasses.join(' ')}
                    cellTemplate={
                        description.dxGridCellTemplate
                            ? description.dxGridCellTemplate
                            : description.field === 'actionColumn'
                              ? 'dxGridRowMenuCellTemplateAjax'
                              : 'dxGridFieldCellTemplate'
                    }
                />
            );
        },
        [hasDetailsGrid],
    );

    const mainGridColumns = useMemo(() => {
        if (currentGridDescription && currentGridDescription.columns) {
            return currentGridDescription.columns.map((description, i) =>
                getGridColumnFromDescription(description, `col_${i}`),
            );
        } else {
            return [] as JSX.Element[];
        }
    }, [currentGridDescription]);

    const DetailSection = useCallback(
        (param: any) => {
            if (currentGridDescription?.detailsForm) {
                return (
                    <DocumentFormDetails
                        docId={getDocIdFromLinkedDocId(param.data.key)}
                        detailsForm={currentGridDescription.detailsForm}
                    />
                );
            }

            if (currentGridDescription?.detailsGrid) {
                return (
                    <GridDetails
                        key={param.data.key}
                        scheme={currentGridDescription?.detailsGrid[0]}
                        gridRef={gridRef}
                        service={service}
                        data={dataKey}
                        onOptionChanged={onOptionChanged}
                        detailSection={DetailSection}
                    />
                );
            }

            return <></>;
        },
        [currentGridDescription?.detailsForm, currentGridDescription?.detailsGrid, dataKey, onOptionChanged, service],
    );

    const onCellClick = useCallback((e: any) => {
        if (e.column && e.column.name === 'actionColumn') {
            e.event?.stopImmediatePropagation();
        }
    }, []);

    const onMainRowClick = useCallback(
        (e: any) => {
            if (!currentGridDescription?.options.isMaster) {
                onRowClick?.(e);
            }
        },
        [currentGridDescription],
    );

    const onExporting = useCallback(() => {
        exportGridToExcel(gridRef, 'Экспорт');
    }, []);

    const gridClassName = useMemo(() => {
        return currentGridDescription?.options.cssClasses.join(' ');
    }, [currentGridDescription?.options?.cssClasses]);

    // -------------------------------------------------------------------------------------------------------------------

    const gridControl = (
        <DevExpressDataGrid
            id="grid"
            filterSyncEnabled={true}
            //remoteOperations={true}
            hoverStateEnabled={true}
            columnHidingEnabled={false}
            columnMinWidth={30}
            showColumnHeaders={currentGridDescription?.options.showColumnHeaders}
            columnAutoWidth={currentGridDescription?.options.columnAutoWidth}
            allowColumnReordering={currentGridDescription?.options.allowColumnReordering}
            allowColumnResizing={currentGridDescription?.options.allowColumnResizing}
            columnResizingMode="widget"
            noDataText={currentGridDescription?.options.noDataText}
            rowAlternationEnabled={currentGridDescription?.options.rowAlternationEnabled}
            onCellClick={onCellClick}
            onRowClick={onMainRowClick}
            onContextMenuPreparing={onContextMenuPreparing}
            ref={gridRef}
            className={gridClassName}
            onRowPrepared={onRowPrepared}
            onEditorPreparing={onEditorPreparing}
            onSelectionChanged={onSelectionChanged}
            onCellHoverChanged={onCellHoverChanged}
            onOptionChanged={onOptionChanged}
            onCellPrepared={onCellPrepared}
            onExporting={onExporting}
            wordWrapEnabled={
                userSettings.wordWrapMode === GridWordWrapMode.Trim ||
                userSettings.wordWrapMode === GridWordWrapMode.Wrap
            }
            showBorders={props.showBorders}
            showRowLines={props.showRowLines}
        >
            <LoadPanel
                enabled={true}
                showPane={true}
                showIndicator={true}
                shading={true}
                shadingColor={'rgba(255, 255, 255, 0.8)'}
            />
            {mainGridColumns}

            {hasDetailsGrid && <MasterDetail enabled={true} render={DetailSection} />}
            {customTemplates}
            <Template
                key="template_dxGridFieldCellTemplate"
                name="dxGridFieldCellTemplate"
                //js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
                render={useCallback(
                    (data: any) => {
                        let { value } = data;

                        let key = '';
                        if (data.rowType === 'data') {
                            if (data.data.documentKey) {
                                key = data.data.documentKey;
                            } else if (data.data.key) {
                                key = data.data.key;
                            }
                        }

                        // Для корректного отображения переносов строк заменяем переносы каретки на аналог из html
                        if (typeof value === 'string') {
                            value = value.replaceAll('\n', '<br />');
                        }

                        let col = currentGridDescription?.columns?.find((x) => x.field === data.column.dataField);
                        let ids = key.split('/');
                        let linkedDocId = ids.length > 1 ? ids[1] : ids[0];

                        let className;
                        switch (userSettings.wordWrapMode) {
                            case GridWordWrapMode.NoWrap:
                                className = 'no-wrap';
                                break;
                            case GridWordWrapMode.Trim:
                                className = 'trim-long-text';
                                break;
                            case GridWordWrapMode.Wrap:
                                className = 'wrap';
                                break;
                        }

                        return linkPrefix && linkedDocId !== '-1' && currentGridDescription?.options.urlEnabled ? (
                            col?.dataType === ValueType.Url ? (
                                <DisplayField className={className} type={col?.dataType!} value={value} />
                            ) : (
                                <Link to={`${linkPrefix}/${linkedDocId}`}>
                                    <DisplayField className={className} type={col?.dataType!} value={value} />
                                </Link>
                            )
                        ) : (
                            <DisplayField className={className} type={col?.dataType!} value={value} />
                        );
                    },
                    [currentGridDescription, userSettings],
                )}
            />

            <Template
                key="template_dxGridRowMenuCellTemplateAjax"
                name="dxGridRowMenuCellTemplateAjax"
                render={useCallback((data: any) => {
                    let key = data['key'];

                    return (
                        <ActionButton
                            objId={key}
                            service={actionService}
                            objData={data.data}
                            onModifyData={refreshGrid}
                        />
                    );
                }, [])}
            />

            <Selection
                allowSelectAll={currentGridDescription?.selection.allowSelectAll}
                mode={currentGridDescription?.selection.mode}
                selectAllMode={currentGridDescription?.selection.selectAllMode}
                showCheckBoxesMode={currentGridDescription?.selection.showCheckBoxesMode}
            />

            <FilterRow
                visible={currentGridDescription?.options?.allowFiltersRow}
                applyFilter={applyFilterOnClick ? 'onClick' : 'auto'}
            />

            <GroupPanel
                visible={currentGridDescription?.groupPanel.visible}
                allowColumnDragging={currentGridDescription?.groupPanel.allowColumnDragging}
            />

            <Grouping autoExpandAll={false} />
            <RemoteOperations
                groupPaging={true}
                filtering={true}
                grouping={true}
                paging={true}
                sorting={true}
                summary={true}
            />
            <Pager
                showInfo={true}
                showPageSizeSelector={currentGridDescription?.pager.showPageSizeSelector}
                visible={true}
                showNavigationButtons={true}
                displayMode={'full'}
                allowedPageSizes={
                    currentGridDescription?.pager.pagerSizes
                        ? currentGridDescription?.pager.pagerSizes
                        : allowedPageSizes
                }
            />

            <Paging
                enabled={currentGridDescription?.paging.enabled}
                defaultPageSize={currentGridDescription?.paging.pageSize}
            />

            <Scrolling
                mode={currentGridDescription?.scrolling.mode}
                useNative={!scrollByContent}
                scrollByContent={scrollByContent}
            />

            <Sorting mode="multiple" />

            <Toolbar>
                <Item location="before">
                    {/*кнопка групповых действий*/}
                    <GroupActionButton
                        gridRef={gridRef}
                        controlRef={controlRef}
                        service={actionService}
                        onModifyData={onGroupActionComplete}
                    />
                </Item>

                <Item name="groupPanel" location="before" visible={currentGridDescription?.groupPanel.visible} />

                {toolbarItems}

                <Item location="after" key={`predefined_filters`} visible={predefinedFiltersEnabled}>
                    <PredefinedFilters
                        key={`predefined_filters`}
                        selectedFilter={selectedPredefinedFilter}
                        onChangeSelected={onChangeSelectedPredefinedFilter}
                        loadFilters={loadPredefinedFilters}
                        gridRef={gridRef}
                    />
                </Item>

                <Item name="applyFilterButton" location="after" visible={applyFilterOnClick} />

                <Item location="after" visible={currentGridDescription?.options.ods_format ?? false}>
                    <Tooltip openDelay={100} background="black" position="top">
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xxs"
                            aria-label="Экспортировать данные в формате ODS"
                            onClick={onClickExport}
                            startAdornment={<MdDownload size="24" />}
                        />
                        Экспортировать данные в формате ODS
                    </Tooltip>
                </Item>

                <Item location="after" visible={currentGridDescription?.options.excel_format ?? false}>
                    <Tooltip openDelay={100} background="black" position="top">
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xxs"
                            aria-label="Экспортировать данные в формате .xls"
                            onClick={onClickExport}
                            startAdornment={<MdDownload size="24" />}
                        />
                        Экспортировать данные в формате .xls
                    </Tooltip>
                </Item>

                <Item location="after">
                    <GridSettingsButton
                        value={userSettings}
                        onChange={(value) => {
                            setUserSettings(value);
                            needReopenMenu.current = true;
                        }}
                        onClickStateChooser={onClickStateChooser}
                        onClickRefresh={onClickRefresh}
                        onClickColumnChooser={onClickColumnChooser}
                        menuSettingsRef={userSettingsRef}
                    />
                </Item>

                <Item name="saveButton" location="after" />
            </Toolbar>
            {currentGridDescription?.columns.some((x) => x.allowEdit === true) && (
                <Editing mode="batch" newRowPosition="last" refreshMode="full" allowUpdating={true} />
            )}
        </DevExpressDataGrid>
    );

    return (
        <>
            <StateModal
                stateKey={dataKey}
                show={showStateModal}
                stateSettings={stateData}
                onCloseClick={() => setShowStateModal(false)}
                onRefreshGrid={onClickRefresh}
                onSelectState={(stateData: IGridStateSettings[] | undefined) => {
                    setStateData(stateData);
                    setShowStateModal(false);
                }}
                componentRef={gridRef}
                stateService={stateService}
            />
            <div className={'dxGrid-wrapper'}>{gridControl}</div>
            <ColumnChooser columnChooserRef={columnChooserRef} selectedOnTop={true} sortOrder="asc" />
        </>
    );
};

export default DevExpressDataGridDynamic;
