import '../ActivityActions.scss';
import React, { useEffect, useRef, useState } from 'react';
import { AttachFilesBlock, IAttachFilesBlockRefActions } from '@atoms/AttachFilesBlock/AttachFilesBlock';
import { IAttachesCreateModel } from '@models/attaches/IAttachesCreateModel';
import { IActivityActionModel } from '@models/document/activityExecutors/IActivityActionModel';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { ISelectedFiles } from '@atoms/AttachFilesBlock/ISelectedFiles';
import { checkAttachValidation } from '@utils/documentUtils';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import Textarea from '@atoms/Textarea';
import { IBaseApproveProps } from './IBaseApproveProps';

export const BaseApproveModal: React.FC<IBaseApproveProps> = (props: IBaseApproveProps) => {
    const childFilesBlock = useRef<IAttachFilesBlockRefActions | null>(null);
    const [attachesCreateModel, setAttachesCreateModel] = useState<IAttachesCreateModel>();
    const [actionModel, setActionModel] = useState<IActivityActionModel>();

    const [comment, setComment] = useState<string>();

    const [errorText, setErrorText] = useState<string>();
    const [warningText, setWarningText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [loadingText, setLoadingText] = useState<string>();

    useEffect(() => {
        setLoadingText('Загрузка настроек...');
        setLoading(true);
        let activityExecutionService = new ActivityExecutionService();
        activityExecutionService.getActivityActionModel(props.actionKey, props.activityId).then((res) => {
            setActionModel(res.data);
            setComment(res.data.prefilledComment);
            setLoading(false);
        });

        //получение модели для аттачей
        if (props.attachFiles) {
            let attachServ = new AttachActionsService(props.docId, '-1');
            attachServ.getAttachInfo(props.actionKey, props.activityId).then((res) => {
                setAttachesCreateModel(res.data);
            });
        }
    }, []);

    const onSubmit = async () => {
        setLoading(true);

        let attachServ = new AttachActionsService(props.docId, '-1');

        try {
            if (props.objId) {
                let filesToSend: ISelectedFiles = { files: [], attachProperties: [] };

                if (attachesCreateModel && childFilesBlock) {
                    if (!childFilesBlock?.current?.isValid()) {
                        setLoading(false);
                        return;
                    }
                    let f = childFilesBlock?.current?.getData();
                    if (f) filesToSend = f;
                }

                // проверка на обязательность наличия аттачей
                if (
                    props.attachFiles &&
                    props.attachRequired &&
                    (filesToSend?.files === undefined || filesToSend?.files.length === 0)
                ) {
                    setWarningText('Необходимо приложить файл');
                    setLoading(false);
                    return;
                }
                if (props.attachRequiredValidation && props.attachRequiredValidation.length > 0) {
                    let checkResult = checkAttachValidation(props.attachRequiredValidation, filesToSend);
                    if (checkResult.length > 0) {
                        let text = checkResult.join('\n');
                        setWarningText(text);
                        setLoading(false);
                        return;
                    }
                }

                if (filesToSend?.files?.length > 0) {
                    await attachServ.createAttaches(filesToSend);
                }

                let actId = getActivityIdFromCompositeId(props.objId);

                setLoadingText(`Отправка на сервер...`);

                let activityExecutionService = new ActivityExecutionService();
                await activityExecutionService.activityAction(
                    props.actionKey,
                    actId,
                    props.approveModel.actType,
                    comment,
                );

                setLoading(false);
                if (props.completeHandler) props.completeHandler(true);
                return;
            }
        } catch (e: any) {
            setErrorText(e);
            console.error(e);
        }

        setLoading(false);
    };

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>
                        <span>{props.displayName}</span>
                    </div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props?.completeHandler) props?.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        disabled={
                            (props.approveModel.commentIsRequired &&
                                (comment === undefined || comment?.length === 0)) ||
                            loading
                        }
                        onClick={async () => {
                            await onSubmit();
                        }}
                        size="s"
                        aria-label={props.okButtonText}
                    >
                        {props.okButtonText}
                    </Button>
                    <Button
                        onClick={() => {
                            if (props?.completeHandler) props?.completeHandler(false);
                        }}
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        disabled={loading}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <div>
                    <Preloader size="m" position="static" />
                    {loadingText}
                </div>
            ) : (
                <>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}
                    {warningText && (
                        <Hint icon="info" title={`Внимание: ${warningText}`} variant="yellow" maxWidth="100%" />
                    )}

                    <div>
                        <Textarea
                            className={clsx(
                                props.approveModel.commentIsRequired &&
                                    (comment == undefined || comment.length == 0) &&
                                    'invalid',
                            )}
                            initialRowCount={8}
                            placeholder={'Комментарий' + (props.approveModel.commentIsRequired ? ' (обязательно)' : '')}
                            defaultValue={actionModel?.prefilledComment}
                            required={props.approveModel.commentIsRequired}
                            onChange={(e: any) => {
                                setComment(e.target.value);
                            }}
                        />
                    </div>
                    <div>
                        {attachesCreateModel && <AttachFilesBlock ref={childFilesBlock} model={attachesCreateModel} />}
                    </div>
                </>
            )}
        </Modal>
    );
};
