import './VoteQuestionWithSignExecutor.scss';
import '../../Signing.scss';
import React, { useEffect, useState } from 'react';
import { ICertificate } from '@models/certificates/cert';
import { ParticipantVoteType } from '@models/document/actionsExecution/ParticipantVoteType';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { IAbookOptions } from '@models/Forms/IAbookOptions';
import { IVoteWithSignResult, IVotingWithSignModel } from '@models/document/actionsExecution/IVotingWithSignModel';
import { IFormValues } from '@models/Forms/IForms';
import { getABookFormValues } from '@utils/abookUtils';
import { CadespluginService } from '@services/CadespluginService';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { Buffer } from 'buffer';
import { useForm } from 'react-hook-form';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import FormProviderControl from '@controls/FormProviderControl';
import FormGroup from '@atoms/FormGroup';
import Abookpicker from '@atoms/Abookpicker';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import Radio from '@atoms/Radio';
import Box from '@atoms/Box';
import Moment from 'moment';
import FatalError from '@molecules/FatalError';
import FatalErrorIcon from '@molecules/FatalError/FatalErrorIcon';
import Textarea from '@atoms/Textarea';
import { IVoteWithSignProps } from './IVoteWithSignProps';

export const VoteQuestionWithSignExecutorModal: React.FC<IVoteWithSignProps> = (props: IVoteWithSignProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [certList, setCertList] = useState<ICertificate[]>();

    //const [selectedCertThumb, setSelectedCertThumb] = useState<string>();
    const [selectedCert, setSelectedCert] = useState<ICertificate>();

    const [comment, setComment] = useState<string>();
    const [decision, setDecision] = useState<ParticipantVoteType>();
    const [participantId, setParticipantId] = useState<number>();
    const [participantName, setParticipantName] = useState<string>();
    const user = useTypedSelector((state) => state.auth.user);

    const [abookOptions, setAbookOptions] = useState<IAbookOptions>();
    const [jsonModelsToSignDict, setJsonModelsToSignDict] = useState<Record<string, IVotingWithSignModel>>();

    const [formValues, setFormValues] = useState<string>('{}');
    const activated = React.useRef(false);

    const getFormValues = async (docId: string, fValues?: IFormValues) => {
        const vals = await getABookFormValues(docId, fValues);
        if (activated.current && vals) {
            setFormValues(vals);
        }
    };

    const loadCertificates = async () => {
        let serv = new CadespluginService();
        let certs = await serv.getCertListByAlgSubstring(['rsa'], []); //тут только rsa
        //если сертификат один - сразу делаем его выбранным
        if (certs && certs.length === 1) {
            //setSelectedCertThumb(certs[0].thumbprint);
            setSelectedCert(certs[0]);
        }
        setCertList(certs);
    };

    useEffect(() => {
        setLoading(true);
        activated.current = true;

        loadCertificates().catch((error) => setErrorText(error));

        let serv = new DocumentExecutionService(props.docId);
        serv.getVotingWithSignModel(props.action.key)
            .then((res) => {
                let dict = res.data;
                let firstDocId = Object.keys(dict)[0];
                let abookOptions = dict[firstDocId].aBookOptions;
                setAbookOptions(abookOptions);
                getFormValues(firstDocId, abookOptions?.formValues);
                setJsonModelsToSignDict(dict);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setLoading(false);
            });

        // Если голосующий Участник комиссии, то берем голос из конфигурации и записываем голосующим текущего пользователя
        if (props.userType === 'participant') {
            switch (props.participantVote) {
                case 'yes':
                    setDecision(ParticipantVoteType.Yes);
                    break;
                case 'no':
                    setDecision(ParticipantVoteType.No);
                    break;
            }

            setParticipantId(+user.id);
            setParticipantName(user.fullName);
        }
        return () => {
            activated.current = false;
        };
    }, []);

    useEffect(() => {
        for (const key in jsonModelsToSignDict) {
            jsonModelsToSignDict[key].jsonModel.comment = comment ?? '';
            jsonModelsToSignDict[key].jsonModel.vote = decision as number;
        }
    }, [comment, decision]);

    const onDecisionChanged = (e: any) => {
        let num = +e.target.value;
        let vote = num as ParticipantVoteType;
        setDecision(vote);
    };

    const onSubmit = async () => {
        if (selectedCert && decision !== undefined && participantId) {
            try {
                setLoading(true);

                let voteDataRecord: Record<string, IVoteWithSignResult> = {};

                for (const key in jsonModelsToSignDict) {
                    let jsonToSign: string = JSON.stringify(jsonModelsToSignDict[key].jsonModel);
                    let jsonToSignBase64 = Buffer.from(jsonToSign).toString('base64');
                    let cadesServ = new CadespluginService();
                    let sign = await cadesServ.signData(jsonToSignBase64, selectedCert.thumbprint);

                    let voteResult: IVoteWithSignResult = {
                        jsonModel: jsonToSign,
                        jsonModelBase64: jsonToSignBase64,
                        sign: sign.sign,
                        voteType: decision,
                        participantId: participantId,
                        certThumbprint: selectedCert.thumbprint,
                        certValidFrom: selectedCert.validFrom,
                        certValidTo: selectedCert.validTo,
                        certSerialNumber: selectedCert.serialNumber,
                        certIssuedToName: selectedCert.subjectName,
                        certIssuedToEmail: selectedCert.issuedToEmail,
                    };

                    voteDataRecord[key] = voteResult;
                }

                let execServ = new DocumentExecutionService(props.docId);
                await execServ.sendSignedVote(props.action.key, voteDataRecord);

                if (props.completeHandler) props.completeHandler(true);
            } catch (e: any) {
                if (typeof e === 'string') {
                    setErrorText(e);
                } else if (Array.isArray(e)) {
                    const errorText = e.join('\n');
                    setErrorText(errorText);
                } else {
                    const errorText =
                        'Ошибка при подписании. Убедитесь, что в браузере установлен и настроен плагин КриптоПро ЭЦП. ' +
                        'Проверить работу плагина: https://cryptopro.ru/sites/default/files/products/cades/demopage/cades_bes_sample.html';
                    setErrorText(errorText);
                }
                console.error(e);
            } finally {
                setLoading(false);
            }
        }
    };

    const formState = useForm<any>({
        mode: 'onBlur', // "onChange"
    });

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>{props.action.displayName}</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props.completeHandler) props.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        disabled={
                            !selectedCert ||
                            (props.userType === 'secretary' && !participantId) ||
                            decision === undefined ||
                            (decision === 0 && (!comment || comment.length === 0)) ||
                            loading
                        }
                        size="s"
                        aria-label={props.okButtonText}
                        onClick={async () => {
                            await onSubmit();
                        }}
                    >
                        {props.okButtonText}
                    </Button>
                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                        disabled={loading}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth="100%" />}
                    {jsonModelsToSignDict && Object.keys(jsonModelsToSignDict).length > 1 ? (
                        <Hint
                            icon="info"
                            title={`Голос будет применен ко всем выбранным вопросам!`}
                            variant="yellow"
                            maxWidth="100%"
                        />
                    ) : null}
                    <FormProviderControl
                        formMethods={formState}
                        onSubmit={(e) => {
                            console.log(e);
                        }}
                    >
                        {props.userType === 'secretary' ? (
                            <FormGroup label="Участник комиссии" className="frmGroup">
                                <Abookpicker
                                    isMultiple={false}
                                    title="Участник комиссии"
                                    displayValue={participantName ?? ''}
                                    formValues={formValues}
                                    name="participant"
                                    showChips={false}
                                    tabsSettings={abookOptions?.tabs}
                                    onChange={async (value: IAddressBookData[]) => {
                                        if (value && value.length === 1) {
                                            setParticipantId(value[0].key);
                                            setParticipantName(value[0].fullName);
                                        }
                                    }}
                                />
                            </FormGroup>
                        ) : (
                            <></>
                        )}
                        {props.userType === 'secretary' ||
                        (props.userType === 'participant' && props.participantVote === 'selectable') ? (
                            <FormGroup label="Решение" className="frmGroup">
                                <Radio name="decision" label="За" value="1" onChange={onDecisionChanged} />
                                <Radio name="decision" label="Против" value="0" onChange={onDecisionChanged} />
                            </FormGroup>
                        ) : (
                            <></>
                        )}
                        <FormGroup label="Сертификаты" className="frmGroup frmGroupCerts">
                            <div className="certList">
                                {certList && certList.length > 0 ? (
                                    certList.map((cert, i) => (
                                        <Box key={i.toString()} className="certificateBox">
                                            <input
                                                type="radio"
                                                name="certSelection"
                                                value={cert.thumbprint}
                                                onChange={(e) => {
                                                    setSelectedCert(
                                                        certList.find((x) => x.thumbprint === e.target.value),
                                                    );
                                                }}
                                                checked={cert.thumbprint === selectedCert?.thumbprint}
                                            ></input>
                                            <div>
                                                <div>
                                                    <b>Владелец сертификата </b>
                                                    {cert.subjectName}
                                                </div>
                                                <div>
                                                    <b>Центр выдачи сертификата </b>
                                                    {cert.issuerName}
                                                </div>
                                                <div>
                                                    <b>Действителен с </b>
                                                    {Moment(cert.validFrom).format('DD.MM.YYYY')}
                                                </div>
                                                <div>
                                                    <b>Действителен по </b>
                                                    {Moment(cert.validTo).format('DD.MM.YYYY')}
                                                </div>
                                            </div>
                                        </Box>
                                    ))
                                ) : (
                                    <div className="certList--noCertsAvailable">
                                        <FatalError
                                            icon={
                                                <FatalErrorIcon variant="default" size={{ width: 100, height: 100 }} />
                                            }
                                            title="Нет доступных сертификатов"
                                            description=""
                                        />
                                    </div>
                                )}
                            </div>
                        </FormGroup>

                        <FormGroup className="frmGroup">
                            <Textarea
                                placeholder={
                                    'Комментарий' + (decision === ParticipantVoteType.No ? ' (обязательно)' : '')
                                }
                                defaultValue={comment}
                                rows={6}
                                required={decision === ParticipantVoteType.No}
                                onChange={(e: any) => {
                                    setComment(e.target.value);
                                }}
                            />
                        </FormGroup>
                    </FormProviderControl>
                </div>
            )}
        </Modal>
    );
};
