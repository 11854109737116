import React, { FC, HTMLProps, useEffect, useRef, useState } from 'react';
import './Textarea.scss';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import clsx from 'clsx';
import { MdClose } from 'react-icons/md';

export interface ITextareaProps extends HTMLProps<HTMLTextAreaElement> {
    /** Автоматическое изменение высоты */
    autoResize?: boolean;
    /** Количество строк */
    initialRowCount?: number;
    /** Дебаунс */
    debounce?: number;
    /** Вернуть value */
    getValue?: (value: string) => void;
    /** Переводит инпут в невалидный статус */
    invalid?: boolean;
    /** обработка ввода комментария с эффектом debounce */
    onDebounce?: (e: Event) => void;
    /** Возможность очистки поля по клику */
    onClear?: () => void;
    onValueChange?: (value: string) => void;
    testid?: string;
}

const Textarea: FC<ITextareaProps> = ({
    className = '',
    autoResize = false,
    initialRowCount = 3,
    debounce = 300,
    getValue,
    disabled,
    readOnly,
    invalid,
    onFocus,
    onBlur,
    onDebounce = () => {},
    onClear,
    onValueChange,
    onChange,
    testid,
    ...props
}: ITextareaProps) => {
    /** Ссылка на поле */
    const textarea = useRef<HTMLTextAreaElement>(null);

    const [value, setValue] = useState<string>(
        props.defaultValue?.toString() || (props.value !== null && props.value?.toString()) || '',
    );
    const [length, setLength] = useState<number>(
        props.defaultValue?.toString().length || (props.value !== null && props.value?.toString().length) || 0,
    );
    /** Находится ли инпут в состоянии фокуса */
    const [isFocused, setFocused] = useState(false);

    /** Сохраненное положение курсора */
    const [cursor, setCursor] = useState<number | null>(null);

    useEffect(() => {
        /** Подписываемся на ввод текста */
        let sub: any;

        if (textarea.current) {
            sub = fromEvent(textarea.current, 'keyup')
                .pipe(
                    map((e: Event) => e),
                    debounceTime(debounce),
                    distinctUntilChanged(),
                )
                .subscribe((e: Event) => {
                    if (textarea.current) {
                        if (props.maxLength) {
                            setValue(textarea.current.value);
                        }

                        getValue && getValue(textarea.current.value);
                    }

                    // @ts-ignore
                    props.onKeyUp && props.onKeyUp(e);
                    onDebounce(e);
                });
        }

        return () => {
            try {
                if (sub) {
                    sub.unsubscribe();
                }
            } catch (e) {
                //  console.log(e);
            }
        };
    }, [props.maxLength, autoResize, onDebounce, debounce]);

    useEffect(() => {
        setValue(props.value?.toString()!);
    }, [props.value]);

    // ------------------------------------------------------------------------------------------------------------------

    const onInputFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        setFocused(true);

        if (onFocus) {
            onFocus(event);
        }
    };

    const onInputBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        setFocused(false);

        if (onBlur) {
            onBlur(event);
        }
    };

    const onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) {
            onChange(event);
        }
        if (onValueChange) {
            setValue(event.currentTarget.value);
            onValueChange(event.currentTarget.value);
        }
        setLength(event.currentTarget.value.length);

        /** Сохраняем положение курсора */
        setCursor(event.target.selectionStart);
    };

    useEffect(() => {
        /** Возвращаем положение курсора */
        textarea.current?.setSelectionRange(cursor, cursor);
    }, [textarea, cursor, value]);

    // ------------------------------------------------------------------------------------------------------------------

    /** Очистка поля ввода */
    const clearInput = () => {
        if (textarea.current) {
            textarea.current.value = '';
            setValue('');
            onClear && onClear();
        }
    };

    /** Кнопка сброса */
    const closeButton = onClear && value.length > 0 && (
        <button type="button" className="rf-textarea__action" onClick={clearInput} aria-label="Сбросить">
            <MdClose />
        </button>
    );

    // ------------------------------------------------------------------------------------------------------------------

    // Делаем проверку на className для обратной совместимости.
    const isInvalid = invalid || (className && className.indexOf('invalid') !== -1);

    const onKeyDown = (e: React.KeyboardEvent) => {
        // для переноса строки
        if (e.key.toLowerCase() === 'enter') {
            e.stopPropagation();
        }
    };

    return (
        <div className={clsx('rf-textarea', className)}>
            <div
                className={clsx(
                    'rf-textarea__wrapper',
                    disabled && 'rf-textarea__wrapper--disabled',
                    readOnly && 'rf-textarea__wrapper--readonly',
                    isFocused && 'rf-textarea__wrapper--focused',
                    isInvalid && 'rf-textarea__wrapper--invalid',
                    autoResize && 'rf-textarea__wrapper--auto-resize',
                    /*!autoResize && "rf-textarea--scroll",*/
                    onClear && value.length > 0 && 'rf-textarea--clearable',
                )}
                data-replicated-value={props.value}
            >
                <textarea
                    {...props}
                    value={value}
                    disabled={disabled}
                    readOnly={readOnly}
                    ref={textarea}
                    rows={initialRowCount}
                    className={clsx('rf-textarea__field' /*, !autoResize && "rf-textarea--scroll"*/)}
                    autoComplete="off"
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    onChange={onTextAreaChange}
                    onKeyDown={onKeyDown}
                    data-testid={testid ? `textarea-${testid}` : undefined}
                />
                {props.maxLength !== undefined && props.maxLength > 0 && (
                    <div
                        className={clsx(
                            'rf-textarea__max-length',
                            'rf-textarea__max-length--float',
                            'rf-textarea__max-length--bottom',
                        )}
                    >
                        Осталось символов: {props.maxLength - length}/{props.maxLength}
                    </div>
                )}
                {closeButton}
            </div>
        </div>
    );
};

export default Textarea;
