import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ICreateDocByTemplateDialogModel } from '@models/document/actionsExecution/ICreateDocByTemplateDialogModel';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { FilesService } from '@services/FilesService';
import { Column, DataGrid, Paging, Selection } from 'devextreme-react/data-grid';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { ResultDocumentActionType } from '@models/document/actionsExecution/ICreateDocByTemplateModel';
import { ICreateDocByTemplateDialogExecutorProps } from './ICreateDocByTemplateDialogExecutorProps';

export const CreateDocByTemplateDialogExecutorModal: React.FC<ICreateDocByTemplateDialogExecutorProps> = (
    props: ICreateDocByTemplateDialogExecutorProps,
) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();

    const [templatesModel, setTemplatesModel] = useState<ICreateDocByTemplateDialogModel>();
    const [templatesList, setTemplatesList] = useState<any[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const service = new DocumentExecutionService(props.docId);
    const gridRef = useRef<DataGrid>(null);

    useEffect(() => {
        setIsBusy(true);
        service
            .getCreateDocByTemplateDialogModel(props.actionKey)
            .then((res) => {
                setTemplatesModel(res.data);
                setTemplatesList(res.data.tableValues);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    }, []);

    const onSelectionChanged = useCallback((e: any) => {
        setSelectedKeys(e.selectedRowKeys);
    }, []);

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>{props.title}</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props.completeHandler) props.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        disabled={selectedKeys.length === 0 || isBusy}
                        size="s"
                        aria-label={props.okButtonText}
                        onClick={() => {
                            setIsBusy(true);

                            let selectedRows = gridRef?.current?.instance.getSelectedRowsData();
                            if (templatesModel) {
                                if (templatesModel.result === ResultDocumentActionType.Download) {
                                    if (selectedRows) {
                                        FilesService.downloadFile(
                                            `documentExecution/downloadDialogTemplateDoc/${props.docId}/${props.actionKey}/${selectedRows[0].templateKey}`,
                                        );
                                    }
                                    props.completeHandler && props.completeHandler(false);
                                } else {
                                    service
                                        .createDocByTemplateDialog(
                                            props.actionKey,
                                            selectedRows?.map((row) => row.templateKey as string) as string[],
                                        )
                                        .then(() => {
                                            setIsBusy(false);
                                            props.completeHandler && props.completeHandler(true);
                                        })
                                        .catch((error) => setErrorText(error))
                                        .finally(() => {
                                            setIsBusy(false);
                                        });
                                }
                            } else {
                                setErrorText('Не удалось получить модель данных.');
                                setIsBusy(false);
                            }
                        }}
                    >
                        {props.okButtonText}
                    </Button>
                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                        disabled={isBusy}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <DevExpressDataGrid
                        dataSource={templatesList}
                        remoteOperations={true}
                        hoverStateEnabled={true}
                        columnHidingEnabled={false}
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        noDataText={'Шаблоны отсутствуют'}
                        rowAlternationEnabled={true}
                        onSelectionChanged={onSelectionChanged}
                        ref={gridRef}
                        onCellHoverChanged={onCellHoverChanged}
                    >
                        <Selection
                            mode="multiple"
                            showCheckBoxesMode={'always'}
                            allowSelectAll={true}
                            selectAllMode={'allPages'}
                        />
                        {templatesModel?.tableColumns.map((column, i) => {
                            return (
                                <Column
                                    key={`col_${i}`}
                                    caption={`${column.title}`}
                                    dataField={`${column.dataField}`}
                                    dataType={'string'}
                                    allowSorting={false}
                                    allowHeaderFiltering={false}
                                    allowReordering={false}
                                    width={`${column.width}`}
                                />
                            );
                        })}
                        <Paging enabled={true} defaultPageSize={templatesModel?.pageSize} />
                    </DevExpressDataGrid>
                </div>
            )}
        </Modal>
    );
};
