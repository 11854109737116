import React, { useEffect, useState } from 'react';
import { IOption } from '@/types';
import { Controller, FormState, SubmitHandler, useForm } from 'react-hook-form';
import { DictionariesService } from '@services/DictionariesService';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import InputControl from '@controls/InputControl';
import { SelectControl } from '@controls/index';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import FormProviderControl from '@controls/FormProviderControl';
import HookFormProvider from '@controls/HookFormProvider';
import { IEditAttachProps } from './IEditAttachProps';

export const EditAttachModal: React.FC<IEditAttachProps> = (props: IEditAttachProps) => {
    const activated = React.useRef(false);
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();
    const [dictsData, setDictsData] = useState<{ [fieldKey: string]: IOption[] }>();
    const [readOnlyProps, setReadOnlyProps] = useState<{ [fieldKey: string]: boolean }>({});

    const editAttachmentFormState = useForm<any>({
        mode: 'onBlur', // "onChange"
    });

    useEffect(() => {
        activated.current = true;
        return () => {
            activated.current = false;
        };
    }, []);

    useEffect(() => {
        if (props.options.Options.Flows[0] === undefined) return;
        setIsBusy(true);

        // если множественное редактирование - используем ИД первого аттача
        let attachIdsSplit = props.attachId.split(',').filter((item) => item);
        let firstAttachId = attachIdsSplit[0];

        let flowProperties: any[] = Array.isArray(props.options.Options.Flows[0].Property)
            ? props.options.Options.Flows[0].Property
            : [props.options.Options.Flows[0].Property];

        let propertiesToEdit: string[] = flowProperties.map((x) => x['@key']);

        let dictsNamesToLoad: string[] = flowProperties.map((x) => x['@dictName']).filter((x) => x);
        DictionariesService.getAllDictsItems(dictsNamesToLoad).then((dictResData) => {
            let service = new AttachActionsService(props.docId, '-1');
            service.getAttachProperties(propertiesToEdit, firstAttachId).then((res) => {
                let newResetState: { [index: string]: any } = {};
                let newReadOnlyProps: { [index: string]: boolean } = {};

                for (let key in res.data) {
                    newResetState[key] = res.data[key].value;
                    newReadOnlyProps[key] = res.data[key].isReadOnly;
                }

                let dicts: { [fieldKey: string]: IOption[] } = {};
                let dictsFields = flowProperties.filter((x) => x['@dictName']);
                dictsFields.forEach((fld) => {
                    let fldKey = fld['@key'];
                    let existingDictValue = newResetState[fldKey];

                    let dictOptions = dictResData.data[fld['@dictName']].map((x) => {
                        return {
                            value: fld['@valueColumn']
                                ? x.fields.find((f) => f.name == fld['@valueColumn'])?.value
                                : x.code,
                            label: x.code,
                        } as IOption;
                    });

                    dicts[fldKey] = dictOptions;

                    //Дефолтовое значение берем из справочника. Либо же добавляем в список, если оно на нашлось
                    let defaultFormValues: IOption[] = dictOptions.filter(
                        (dictRow) => dictRow.value == existingDictValue,
                    );
                    newResetState[fldKey] =
                        defaultFormValues.length > 0
                            ? defaultFormValues
                            : [
                                  {
                                      value: res.data[fld['@key']]?.value,
                                      label: res.data[fld['@key']]?.value,
                                  } as IOption,
                              ]; //dictResData.data[fld['@dictName']].find(dictRow => dictRow.code == existingDictValue);
                });

                let nonMultiEditableFields = flowProperties.filter((x) => x['@multiEditable'] !== 'true');
                let attachIdsSplit = props.attachId.split(',').filter((item) => item);
                if (attachIdsSplit.length > 1) {
                    // если множественное редактирование - фильтрует по атрибуту @multiEditable
                    nonMultiEditableFields.forEach((fld) => {
                        let fldKey = fld['@key'];
                        delete newResetState[fldKey];
                    });

                    let promises: any = [];
                    attachIdsSplit.forEach((attId: string) => {
                        promises.push(
                            // получаем ReadOnlyProps для всех выбранных аттачей
                            service.getAttachProperties(propertiesToEdit, attId).then((result) => {
                                for (let key in result.data) {
                                    newReadOnlyProps[key] = newReadOnlyProps[key] || result.data[key].isReadOnly;
                                }
                            }),
                        );
                    });

                    Promise.all(promises).then(() => {
                        // readOnly поля тоже исключаются из группового редактирования
                        for (const k in newReadOnlyProps) {
                            if (newReadOnlyProps[k]) delete newResetState[k];
                        }

                        if (activated.current) {
                            setDictsData(dicts);
                            setReadOnlyProps(newReadOnlyProps);

                            editAttachmentFormState.reset({});
                            editAttachmentFormState.reset(newResetState);
                            setIsBusy(false);
                        }
                    });
                } else {
                    if (activated.current) {
                        setDictsData(dicts);
                        setReadOnlyProps(newReadOnlyProps);

                        editAttachmentFormState.reset({});
                        editAttachmentFormState.reset(newResetState);
                        setIsBusy(false);
                    }
                }
            });
        });
    }, [editAttachmentFormState]);

    const renderControl = (property: any, fileFields: any, index: number) => {
        {
            switch (property['@valueType']) {
                case 'text':
                    return (
                        <InputControl
                            label={property['@displayName']}
                            placeholder={property['@displayName']}
                            name={property['@key']}
                            readOnly={readOnlyProps[property['@key']]}
                            formState={editAttachmentFormState.formState as FormState<any>}
                            control={editAttachmentFormState.control}
                            value={fileFields[property['@key']]}
                        />
                    );
                case 'dict':
                    if (!dictsData) return <></>;
                    return (
                        <SelectControl
                            options={dictsData[property['@key']]}
                            multiselect={false}
                            name={property['@key']}
                            formState={editAttachmentFormState.formState as FormState<any>}
                            control={editAttachmentFormState.control}
                            label={property['@displayName']}
                            onlySelect={true}
                            readOnly={true}
                            disabled={readOnlyProps[property['@key']]}
                            highlightMenuItems={false}
                        />
                    );
            }
        }
    };

    const onSubmit: SubmitHandler<any> = (attachProperties: any) => {
        setIsBusy(true);

        for (const k in attachProperties) {
            if (Array.isArray(attachProperties[k]) && attachProperties[k].length > 0) {
                attachProperties[k] = attachProperties[k][0].value;
            }
        }

        let service = new AttachActionsService(props.docId, '-1');
        service
            .editAttach(attachProperties, props.attachId)
            .then(() => {
                if (props.completeHandler) props.completeHandler(true);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    };

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>Редактирование свойств присоединенного файла</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props.completeHandler) props.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        size="s"
                        aria-label={props.okButtonText}
                        onClick={() => {
                            onSubmit({
                                ...editAttachmentFormState.getValues(),
                            });
                        }}
                        disabled={isBusy}
                    >
                        {props.okButtonText}
                    </Button>
                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                        disabled={isBusy}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth="100%" />}
                    {Array.isArray(props.rowData) && props.rowData.length > 1 ? (
                        <Hint
                            icon="info"
                            title={`Свойства будут изменены у всех выбранных файлов!`}
                            variant="yellow"
                            maxWidth="100%"
                        />
                    ) : (
                        <></>
                    )}
                    {props.options.Options.Flows[0] === undefined ? (
                        <Hint
                            icon="info"
                            title={`Ошибка: не удалось получить конфигурацию действия для данного потока.`}
                            variant="red"
                            maxWidth="100%"
                        />
                    ) : (
                        <FormProviderControl
                            formMethods={editAttachmentFormState}
                            onSubmit={onSubmit}
                            ignoreEnterSubmit={true}
                        >
                            <HookFormProvider controller={Controller}>
                                <div>
                                    {(Array.isArray(props.options.Options.Flows[0].Property)
                                        ? props.options.Options.Flows[0].Property
                                        : [props.options.Options.Flows[0].Property]
                                    )
                                        // если множественное редактирование - фильтрует по атрибуту @multiEditable
                                        .filter((prop: any) => {
                                            return Array.isArray(props.rowData) && props.rowData.length > 1
                                                ? prop['@multiEditable'] === 'true' && !readOnlyProps[prop['@key']]
                                                : true;
                                        })
                                        .map((property: any, index: number) => (
                                            <div key={index}>
                                                {Array.isArray(props.rowData)
                                                    ? renderControl(property, props.rowData[0].fields, index)
                                                    : renderControl(property, props.rowData.fields, index)}
                                            </div>
                                        ))}
                                </div>
                            </HookFormProvider>
                        </FormProviderControl>
                    )}
                </div>
            )}
        </Modal>
    );
};
