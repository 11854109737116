import './SearchModeControl.scss';
import DataGrid, { Column, Selection } from 'devextreme-react/data-grid';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { IExternalSearchDict, IForms } from '@models/Forms/IForms';
import { DictionariesService } from '@services/DictionariesService';
import { IField, IFieldElem, IFormData } from '@models/IFormData';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { convertListToMap, getFieldKeysByScheme } from '@utils/documentUtils';
import FormBuilder from '@molecules/formbuilder/FormBuilder';
import Button from '@atoms/Button';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { MdSearch } from 'react-icons/md';
import { sendErrorMsg } from '@molecules/Errors';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import FormProviderControl from '@controls/FormProviderControl';
import HookFormProvider from '@controls/HookFormProvider';

export interface ISearchModeControlProp extends IExternalSearchDict {
    onSelectedRowItems: (value: any) => void;
}

const SearchModeControl: FC<ISearchModeControlProp> = (p: ISearchModeControlProp) => {
    const gridRef = useRef<DataGrid>(null);
    const [form, setForm] = useState<IForms>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchResult, setSearchResult] = useState<any>();
    const methods = useForm<IFormData>({
        mode: 'onBlur', // "onChange"
    });
    const [fieldsData, setFieldsData] = useState<Record<string, IFieldElem>>();
    let fieldsName: number[] = [];
    useEffect(() => {
        DictionariesService.fetchExternalSearchScheme(p.searchForm).then((response) => {
            const keys = getFieldKeysByScheme(response.data.form.view.rows.row);
            const uniqueKeys = keys.filter((v, i, a) => v !== undefined && a.indexOf(v) === i);
            let formFields: IField[] = [];
            uniqueKeys.forEach((key) => {
                formFields.push({ name: key, value: null } as IField);
            });
            let data = { fields: formFields } as IFormData;

            methods.reset(data);
            setFieldsData(convertListToMap(data.fields, ''));
            setForm(response.data.form);
        });
    }, [p.searchForm]);

    const store = useMemo(() => {
        return new DataSource({
            store: new ArrayStore({
                key: p.resultKey,
                data: searchResult,
            }),
        });
    }, [searchResult]);

    const onSelectionChanged = (e: any) => {
        if (e.currentSelectedRowKeys.length > 0) {
            let item = e.selectedRowsData.pop();
            e.component.selectRows([item[p.resultKey]], false);

            p.onSelectedRowItems(item!);
        } else if (e.selectedRowKeys.length == 0) {
            p.onSelectedRowItems(undefined);
        }
    };

    const onRowClick = (e: any) => {
        let keys = e.component.getSelectedRowKeys();
        const index = keys.indexOf(e.key);
        if (index > -1) {
            keys.splice(index, 1);
        } else {
            keys = [e.key];
        }
    };

    const gridControl = (
        <DevExpressDataGrid
            key="dictsearchGrid"
            showRowLines={true}
            columnAutoWidth={true}
            columnMinWidth={30}
            dataSource={store}
            remoteOperations={true}
            hoverStateEnabled={true}
            columnHidingEnabled={false}
            showColumnHeaders={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            ref={gridRef}
            onSelectionChanged={onSelectionChanged}
            onRowClick={onRowClick}
            onCellHoverChanged={onCellHoverChanged}
        >
            <Selection mode="multiple" allowSelectAll={false} />

            {p.gridAttribute?.attrs?.map((attr, i) => {
                return (
                    <Column
                        key={i}
                        allowFiltering={true}
                        caption={attr.name}
                        dataField={attr.key}
                        dataType={'string'}
                        visible={true}
                        filterOperations={['contains']}
                        encodeHtml={true}
                    />
                );
            })}
        </DevExpressDataGrid>
    );

    const onSubmit: SubmitHandler<IFormData> = (data: IFormData) => {
        const formData = {} as Record<string, any>;

        for (const ele of data.fields) {
            formData[ele.name.substring(1)] = ele.value;
        }
        setIsLoading(true);
        DictionariesService.fetchExternalSearch(p.searchApiUrl, formData)
            .then((response) => {
                setSearchResult(response.data);
            })
            .catch((reason) => {
                sendErrorMsg({
                    message: [reason[0]],
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return form ? (
        <div className="dict-container-search">
            <div className="dict-container-search__form-wrapper">
                <FormProviderControl formMethods={methods} className="doc-form dict-form" onSubmit={onSubmit}>
                    <HookFormProvider controller={Controller}>
                        <div className="dict-container-search-form">
                            <FormBuilder
                                fields={fieldsData!}
                                setError={(errors?: string[]) => {
                                    if (errors) {
                                        sendErrorMsg({
                                            message: errors,
                                        });
                                    }
                                }}
                                rows={form?.view}
                                isEdit={false}
                                isNew={true}
                                formMethods={methods}
                                includedFields={fieldsName}
                                uniqueKey={''}
                            />
                        </div>
                        <div className="dict-search-buttons">
                            <Button
                                buttonType="primary"
                                size="s"
                                type="submit"
                                aria-label="Найти"
                                disabled={isLoading}
                                preloader={isLoading}
                                startAdornment={<MdSearch />}
                            >
                                Найти
                            </Button>
                        </div>
                    </HookFormProvider>
                </FormProviderControl>
            </div>
            <div className="dict-container-search__grid">{gridControl}</div>
        </div>
    ) : (
        <></>
    );
};

export default SearchModeControl;
