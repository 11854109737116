import './ActivityBatchEditingTableExecutor.scss';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalContainer, { IModalContainerProps } from '@molecules/ModalContainer/ModalContainer';
import { IActivityBatchEditingTableProps } from './IActivityBatchEditingTableProps';
import { IDocumentTable } from '@models/Forms/IForms';
import { IFieldElem } from '@models/IFormData';
import EditTable from '@molecules/formbuilder/controls/Table/controls/EditTable';
import { sendErrorMsg } from '@molecules/Errors';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';
import { convertListToMap } from '@utils/documentUtils';

export const ActivityBatchEditingTableModal: React.FC<IActivityBatchEditingTableProps> = (
    props: IActivityBatchEditingTableProps,
) => {
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const service = new ActivityExecutionService();
    const [tableScheme, setTableScheme] = useState<IDocumentTable>();
    const [fields, setFields] = useState<Record<string, IFieldElem>>({});

    const activated = React.useRef(false);

    const formMethods = useForm<any>({
        mode: 'onBlur',
    });

    useEffect(() => {
        activated.current = true;
        setLoading(true);

        Promise.all([
            service.getBatchEditingTableScheme(props.actionKey, props.actId),
            service.getBatchEditingTableData(props.actionKey, props.actId, props.docId),
        ])
            .then((values) => {
                const [scheme, data] = values;
                const tableScheme = scheme.data;
                const baseDocData = data.data.baseDocData;
                const tableData = data.data.tableData;

                let mergedFields = [...tableData.fields, ...baseDocData.fields];
                let mergedFieldsMap = convertListToMap(mergedFields);

                tableScheme.addRowButtonRules = 'false';
                tableScheme.singleRowDeleteButtonRules = 'false';
                tableScheme.removeRowButtonRules = 'false';
                tableScheme.singleRowCopyButtonRules = 'false';
                tableScheme.copyRowButtonRules = 'false';

                formMethods.reset({ ...tableData, fields: mergedFields });
                setTableScheme(tableScheme);
                setFields(mergedFieldsMap);
            })
            .catch((err) => {
                setErrorText('Ошибка получения данных от сервера.');
            })
            .finally(() => setLoading(false));

        return () => {
            activated.current = false;
        };
    }, []);

    const onSubmit = () => {
        setLoading(true);
        const formValues = formMethods.getValues();

        service
            .saveBatchEditingTableData(props.actId, props.docId, props.actionKey, formValues)
            .then((result) => {
                props.completeHandler && props.completeHandler(true);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const modalContainerProps: IModalContainerProps = {
        header: props.title,
        size: props.modalSize,
        showOkButton: true,
        showCancelButton: true,
        okButtonText: props.okButtonText,
        cancelButtonText: props.cancelButtonText,
        isBusy: loading,
        cancelClick: () => {
            props.completeHandler && props.completeHandler(false);
        },
        errorText: errorText,
        formMethods: formMethods,
        onSubmit: onSubmit,
    };

    return (
        <ModalContainer {...modalContainerProps}>
            <div className="activity-batch-editing-table-content">
                {tableScheme && fields && (
                    <EditTable
                        table={tableScheme}
                        formMethods={formMethods}
                        setError={(errors?: string[]) => {
                            if (errors) {
                                sendErrorMsg({
                                    message: errors,
                                });
                            }
                        }}
                        isEdit={true}
                        isNew={false}
                        fields={fields}
                    />
                )}
            </div>
        </ModalContainer>
    );
};
