// eslint-disable-next-line object-curly-newline
import React, {
    FC,
    KeyboardEventHandler,
    ReactNode,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Props as IInfiniteScrollProps } from 'react-infinite-scroll-component';

import { DropdownPosition, IOption } from '@/types';
import Chip from '../Chip';

import { MdClose, MdOutlineExpandMore } from 'react-icons/md';
import clsx from 'clsx';
import DropdownContent from '../DropdownContent';
import usePrevious from '@hooks/usePrevious';

import { MenuVariantSize } from './Select.types';
import SelectMenu from './Select.Menu';
import './Select.scss';
import useDebounce from '@hooks/useDebounce';
import { autoUpdate, flip, offset, shift, useDismiss, useFloating, useInteractions } from '@floating-ui/react';

export interface ISelectProps<T extends HTMLElement = HTMLDivElement> {
    /** Варианты выбора */
    options: IOption[];
    /** Изменение значения */
    onChange?: (option: IOption[]) => void;
    onInputValueChanged?: (val: string) => void;
    /** Значение */
    values?: IOption[];
    defInputValue?: string;
    /** Поиск внутри селекта
     * @param query - строка поиска
     * @param isPagination - указывает что изменилась пагинация
     */
    onSearch?: (query: string, isPagination?: boolean) => void;
    /**
     * Множественный выбор
     * @default false
     *  */
    multiselect?: boolean;
    /** Плейсхолдер */
    placeholder?: string;
    /**
     *  Запрещает вводить текст
     *  @default false
     */
    readOnly?: boolean;
    /**
     * залочен или нет
     * @default false
     *  */
    disabled?: boolean;
    /** Максимальное количество выбранных вариантов при multiselect */
    maxOptions?: number | undefined;
    /**
     * Прелоудер при ленивой загрузке
     * @default false
     *  */
    preloader?: boolean;
    /**
     * Очистить селект при выборе значения
     * @default false
     * */
    clearOnSelect?: boolean;

    onlySelect?: boolean;
    /** Любое изменяемое значение (зависимость). При изменении этого параметра очищается селект */
    /**
     * Вид селекта
     * @default 'base'
     *  */
    variant?: 'base' | 'tag' | 'menu';
    /**
     * Переводит селект в невалидный статус
     * @default false
     *  */
    invalid?: boolean;
    /** Указывает будет ли селект асинхронным
     * Если значение указано true, тогда нужно передавать infinityScrollProps
     * @default false
     */
    isAsync?: boolean;
    /** Пропсы для infinityScroll
     * @requires dataLength текущая длина массива
     * @requires hasMore стоит ли еще загружать данные
     */
    infinityScrollProps?: Omit<IInfiniteScrollProps, 'children' | 'next' | 'scrollableTarget' | 'loader'>;
    /**
     * Расположение
     * @default 'bottom'
     *  */
    position?: DropdownPosition;
    /** Событие скролла для выпадающего списка */
    onScroll?: (e: React.UIEvent) => void;
    startAdornment?: ReactNode | undefined;
    endAdornment?: ReactNode | undefined;
    /** Максимальная ширина выпадающего меню  */
    dropdownMaxWidth?: number | string;
    /** Сcылка на контейнер портала */
    containerRef?: RefObject<T>;
    /**
     * Цвет селекта
     * @default 'white'
     * */
    backgroundColor?: 'white' | 'gray';
    /**
     * Размер кнопки меню
     * @default 'm'
     * */
    menuVariantSize?: MenuVariantSize;

    /**
     * Подсвечивать текст в элементах меню
     * @default true
     * */
    highlightMenuItems?: boolean;

    minCharsToDebounce?: number;
    debounceTime?: number;
    debouncedInput?: (val: string) => void;
    allowEmptyStringDebounce?: boolean;

    testid?: string;

    /**
     *  Отображение иконки сброса значения
     * @default true
     *  */
    showClearIcon?: boolean;
}

const Select: FC<ISelectProps> & { id: number } = ({
    options,
    onChange: propsOnChange,
    invalid = false,
    onSearch,
    values: propsValues,
    defInputValue = '',
    multiselect = false,
    placeholder = '',
    variant = 'base',
    disabled = false,
    readOnly = variant === 'menu',
    maxOptions = undefined,
    preloader = false,
    clearOnSelect = false,
    onlySelect = false,
    isAsync,
    infinityScrollProps,
    position = variant === 'menu' ? 'bottom-end' : 'bottom',
    endAdornment,
    startAdornment,
    onScroll,
    dropdownMaxWidth,
    containerRef,
    backgroundColor = 'white',
    menuVariantSize = 'm',
    minCharsToDebounce,
    debounceTime,
    debouncedInput,
    onInputValueChanged,
    allowEmptyStringDebounce,
    highlightMenuItems = true,
    showClearIcon = true,
    testid,
}: ISelectProps) => {
    const id = useMemo(() => {
        return Select.id++;
    }, []);

    const inputRef = useRef<HTMLInputElement>(null);
    const selectDropDownRef = useRef<HTMLUListElement>(null);

    const [showDropdown, setShowDropdown] = useState(false);
    const toggleRef = useRef<HTMLDivElement>(null);
    const [isOnMove, setIsOnMove] = useState<boolean>(false);
    const firstElementPosition = useRef<number>(0);
    const prevShowDropDown = usePrevious(showDropdown) || false;

    const [valueState, setValueState] = useState<IOption[]>([]);

    const selectValues = propsValues ?? valueState;

    const [inputValue, setInputValue] = useState<string>(() =>
        selectValues.length > 0 && !multiselect ? selectValues[0].label : defInputValue === null ? '' : defInputValue,
    );

    // Floating UI setup
    const { refs, floatingStyles, context } = useFloating({
        open: showDropdown,
        onOpenChange: setShowDropdown,
        whileElementsMounted: autoUpdate,
        placement: position || 'bottom',
        middleware: [offset({ mainAxis: 8, crossAxis: 0 }), flip(), shift()],
    });

    const dismiss = useDismiss(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    useDebounce(inputValue, debounceTime ?? 0, (val) => {
        if ((allowEmptyStringDebounce || val) && val.length >= (minCharsToDebounce ?? 3)) {
            if (showDropdown && debouncedInput) debouncedInput(val);
        }
    });

    const setSelectValues = (next: IOption[]) => {
        // uncontrolled
        if (!propsValues) {
            setValueState(next);
        }

        propsOnChange?.(next);
    };

    /** Очистка селекта */
    const onClear = () => {
        setInputValue('');

        if (!multiselect) {
            setSelectValues([
                {
                    label: '',
                    value: '',
                },
            ]);

            if (debouncedInput) debouncedInput('');
        }

        onSearch && onSearch('');
    };

    const onClearSelectedValues = () => {
        setSelectValues([
            {
                label: '',
                value: '',
            },
        ]);
    };

    const onClose = useCallback(() => {
        if (showDropdown) {
            setShowDropdown(false);
            inputRef.current?.focus();
        }
    }, [showDropdown]);

    const onOpen = useCallback(
        (keepInputFocus: boolean = true) => {
            if (!disabled) {
                setShowDropdown(true);

                if (keepInputFocus) {
                    setTimeout(() => {
                        inputRef.current?.focus();
                    }, 30);
                } else {
                    selectDropDownRef.current?.focus();
                }
            }
        },
        [disabled],
    );

    const handleInputClick = useCallback(() => {
        setShowDropdown((state) => !state);
    }, [setShowDropdown]);

    const onReset = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClear();
        onClearSelectedValues();
        onOpen();
    };

    // -------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        setInputValue(selectValues.length > 0 && !multiselect ? selectValues[0].label : defInputValue);
    }, [selectValues]);

    // -------------------------------------------------------------------------------------------------------------------

    /** Поиск в селекте */
    const onSelectSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;
        setInputValue(val);
        if (val) {
            const filtered = options.filter((o: IOption) => o.label.toLowerCase().includes(inputValue.toLowerCase()));
            setFilteredOptions(filtered);
        }
    };

    // -------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (clearOnSelect) {
            setInputValue('');
        }

        if (multiselect && selectValues.length === 1 && toggleRef.current) {
            toggleRef.current.getBoundingClientRect().y = firstElementPosition.current;
        }
    }, [multiselect, selectValues]);

    const onValueChange = (option: IOption) => {
        let result = undefined;

        if (multiselect) {
            const index = selectValues.findIndex((o: IOption) => option.value === o.value);

            if (index >= 0) {
                result = selectValues.filter((_: IOption, i: number) => i !== index);
            } else {
                if (typeof maxOptions === 'number' ? selectValues.length < maxOptions : true) {
                    result = [...selectValues, option];
                }
            }
        } else {
            result = [option];
        }

        if (result) {
            setSelectValues(result);
        }
    };

    // -------------------------------------------------------------------------------------------------------------------

    const [filteredOptions, setFilteredOptions] = useState<IOption[]>([]);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    // -------------------------------------------------------------------------------------------------------------------

    const handleOptionClick = async (option: IOption) => {
        onValueChange(option);

        if (!multiselect) {
            setInputValue(clearOnSelect ? '' : option.label);
            onClose();
        } else {
            setInputValue('');
        }
    };

    // -------------------------------------------------------------------------------------------------------------------

    const noop = () => {};

    const inputVariantClass =
        variant === 'menu' ? `rf-select__menu${disabled ? '--disabled' : ''} rf-select__menu--${menuVariantSize}` : '';
    const inputMultiselectClass = multiselect && selectValues.length ? 'rf-select__input--multiselect' : '';
    const inputOnMoveClass = isOnMove && variant === 'menu' ? 'rf-button__hover' : '';

    const inputClass = `rf-select__input ${inputMultiselectClass} ${inputVariantClass} ${inputOnMoveClass}`;

    const handleInputKeyDown: KeyboardEventHandler = (event) => {
        //const openKeys = ['ArrowDown', 'Space', 'Enter'];
        const openKeys = ['ArrowDown'];
        if (openKeys.includes(event.code)) {
            event.preventDefault();
            onOpen(false);
        } else {
            onOpen();
        }
    };

    const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (readOnly == undefined || readOnly == false) {
            if (onInputValueChanged) {
                onInputValueChanged(inputValue);
            }
        }
    };

    const inputElement = (
        <input
            ref={inputRef}
            autoSave="false"
            autoComplete="off"
            data-testid={testid ? `simpleselect-${testid}` : undefined}
            className={inputClass}
            onChange={onSelectSearch}
            onKeyDown={handleInputKeyDown}
            onBlur={onInputBlur}
            value={inputValue}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={multiselect && selectValues.length ? '' : options.length ? placeholder : ''}
        />
    );

    const tagsRef = useRef<HTMLDivElement>(null);

    const tagsJSX = multiselect && selectValues.length > 0 && (
        <div className="rf-select__tags" onClick={() => !disabled && onOpen(true)}>
            {selectValues.map((t: IOption, index: number) => (
                <div ref={tagsRef} className={clsx('rf-select__tag')} key={index}>
                    <Chip
                        type="secondary"
                        size="xs"
                        onRemove={() => onValueChange(t)}
                        onClick={noop}
                        disabled={disabled}
                    >
                        {t.label}
                    </Chip>
                </div>
            ))}
            <div className={clsx('rf-select__input--multiselect')}>{inputElement}</div>
        </div>
    );

    // -------------------------------------------------------------------------------------------------------------------

    const closeButton = !disabled && !readOnly && inputValue && inputValue.length > 0 && !onlySelect && (
        <button
            type="button"
            className={`rf-select__button${multiselect && selectValues.length ? '--multiselect' : ''}`}
            onClick={onReset}
            aria-label="Сбросить"
        >
            <MdClose />
        </button>
    );

    const onChevronClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowDropdown((state: boolean) => !state);
    };

    const onMouseMoveHandler = (e: React.MouseEvent) => {
        setIsOnMove(true);
    };

    const onMouseLeaveHandler = (e: React.MouseEvent) => {
        setIsOnMove(false);
    };

    useEffect(() => {
        if (!showDropdown && showDropdown !== prevShowDropDown) {
            if (!filteredOptions.length || !options.find((opt) => opt.label === inputValue)) {
                if (clearOnSelect) {
                    onClear();
                }
            }
        }
    }, [prevShowDropDown, filteredOptions, options]);

    const hasChevron = multiselect
        ? readOnly || (inputValue && inputValue.length === 0) || (inputValue && inputValue.length > 0)
        : readOnly || (inputValue && inputValue.length === 0) || onlySelect;

    const chevronButton = hasChevron && (
        <div
            data-testid="rf-select__chevron"
            className={clsx(
                multiselect && selectValues.length ? 'rf-select__button-multiselect-chevron' : 'rf-select__button',
                showDropdown && 'rf-select__button--rotate',
                variant === 'menu' ? 'rf-select__button--menu' : '',
                isOnMove && variant === 'menu' ? 'rf-button__hover' : '',
            )}
            onClick={onChevronClick}
            onMouseMove={onMouseMoveHandler}
            aria-label={showDropdown ? 'Скрыть меню' : 'Раскрыть меню'}
        >
            {options.length ? <MdOutlineExpandMore size="20" color={variant === 'menu' ? '#fff' : ''} /> : null}
        </div>
    );

    const startAdornmentIcon =
        startAdornment && variant !== 'tag' ? <div className="rf-select__button__icon">{startAdornment}</div> : null;
    const endAdornmentIcon =
        endAdornment && variant !== 'tag' ? <div className="rf-select__button__icon--end">{endAdornment}</div> : null;

    // -------------------------------------------------------------------------------------------------------------------

    const isTagVariant = variant === 'tag';
    const openClass = showDropdown ? 'rf-select__wrapper--open' : '';
    const tagClass = isTagVariant ? 'rf-select__wrapper--tag' : '';

    const getWidthDropdown = useCallback(() => {
        return dropdownMaxWidth || toggleRef.current?.getBoundingClientRect().width;
    }, [dropdownMaxWidth]);

    return (
        <div
            className={clsx('rf-select', tagClass, backgroundColor === 'gray' && 'rf-select__background-gray')}
            ref={toggleRef}
        >
            <div
                ref={refs.setReference}
                onMouseLeave={onMouseLeaveHandler}
                data-testid={testid ? `select-${testid}` : undefined}
                className={clsx({
                    'rf-select__wrapper': true,
                    'rf-select__wrapper--invalid': invalid,
                    [openClass]: true,
                    'rf-select__wrapper--disabled': disabled,
                    'rf-select__wrapper--menu': variant === 'menu',
                })}
                onClick={handleInputClick}
                {...getReferenceProps()}
            >
                {startAdornmentIcon}
                <div
                    className={clsx(
                        multiselect && selectValues.length ? 'rf-select__wrapper--input' : 'rf-select-input__container',
                        !showDropdown && multiselect && selectValues.length ? 'rf-select--multiselect--fixed' : '',
                    )}
                >
                    {tagsJSX}
                    {multiselect ? (selectValues.length < 1 ? inputElement : null) : inputElement}
                </div>
                {endAdornmentIcon}
                {showClearIcon && closeButton}
                {chevronButton}
            </div>

            <DropdownContent
                show={showDropdown && (!!filteredOptions.length || preloader)}
                ref={refs.setFloating}
                containerRef={containerRef}
                position={position}
                style={{
                    ...floatingStyles,
                    maxWidth: isTagVariant ? 'auto' : getWidthDropdown(),
                    width: isTagVariant ? 'auto' : '100%',
                }}
                floatingContext={context}
                getFloatingProps={getFloatingProps}
            >
                <SelectMenu
                    ref={selectDropDownRef}
                    options={filteredOptions}
                    selected={selectValues}
                    multiselect={multiselect}
                    selectId={id}
                    menuVariantSize={menuVariantSize}
                    isAsync={isAsync}
                    infinityScrollProps={infinityScrollProps}
                    query={highlightMenuItems ? inputValue : ''}
                    preloader={preloader}
                    onOptionClick={handleOptionClick}
                    onSearch={onSearch}
                    onClose={onClose}
                    onScroll={onScroll}
                />
            </DropdownContent>
        </div>
    );
};

Select.id = 0;

export default Select;
