import './TableData.scss';
import React, { useEffect, useState } from 'react';
import { IDocumentTable } from '@models/Forms/IForms';
import { isVisibleRowButton } from '@utils/tableHelper';
import clsx from 'clsx';
import Tooltip from '@atoms/Tooltip';

export interface RowCopyButtonProps {
    table: IDocumentTable;
    rowData: any;
    onCopy: (rowData: any) => void;
    docId?: string;
}

const RowCopyButton: React.FC<RowCopyButtonProps> = ({ table, rowData, onCopy, docId }: RowCopyButtonProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        isVisibleRowButton(table.singleRowCopyButtonRules, rowData).then((value) => {
            setIsVisible(value);
        });
    }, []);

    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onCopy(rowData);
    };

    return isVisible ? (
        <a
            href="#"
            className={clsx('dx-link', 'dx-link-delete', 'dx-icon-copy', 'dx-link-icon')}
            onClick={onClick}
            title="Копировать строку"
            aria-label="Копировать строку"
        />
    ) : table.singleRowCopyButtonDisabledTitle ? (
        <Tooltip openDelay={100} background="black" position="top">
            <a
                href="#"
                className={clsx('dx-link', 'dx-link-delete', 'dx-icon-copy', 'dx-link-icon', 'dx-state-disabled')}
            />
            {table.singleRowCopyButtonDisabledTitle}
        </Tooltip>
    ) : null;
};

export default RowCopyButton;
