import React, { FC, useMemo } from 'react';
import './DebugSettings.scss';
import Button from '@atoms/Button';
import Menu from '@atoms/Menu';
import { IDebugSettings } from '@models/IDebugSettings';
import Switch from '@atoms/Switch';
import { MdSettings } from 'react-icons/md';

export interface IDebugSettingsProps {
    value: IDebugSettings;
    onChange: (value: IDebugSettings) => void;
}

const DebugSettings: FC<IDebugSettingsProps> = ({ value: valueProp, onChange }: IDebugSettingsProps) => {
    const menuContent = useMemo(() => {
        return (
            <div className="debug-settings__content">
                <div className="debug-settings__item">
                    <Switch
                        label="Уведомлять об ошибках в формулах"
                        value={valueProp.notifyFormulaErrors}
                        between={true}
                        reverse={true}
                        data={'data'}
                        onChange={(f, value) => {
                            onChange({
                                ...valueProp,
                                notifyFormulaErrors: value,
                            });
                        }}
                    />
                </div>
            </div>
        );
    }, [onChange, valueProp]);

    return (
        <div className="debug-settings">
            <Menu content={menuContent} position="bottom-end" offsetValue={{ mainAxis: 7, crossAxis: 0 }}>
                <div className="debug-settings__menu">
                    <Button
                        buttonType="round"
                        title="Отладочные настройки"
                        data-testid="debug-settings-button"
                        textColor="neutral"
                        size="m"
                        startAdornment={<MdSettings size="32" />}
                    />
                </div>
            </Menu>
        </div>
    );
};

export default DebugSettings;
