import './OfferTechAnalysis.scss';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@atoms/Button';
import clsx from 'clsx';
import { MdClose } from 'react-icons/md';
import Modal from '@atoms/Modal';
import { IOffer } from '@models/winnerSelection/ITableDemand';
import { Controller, useForm } from 'react-hook-form';
import { IOption } from '@/types';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { sendNotification } from '@molecules/Notifications';
import ToolTipTitle from '@atoms/ToolTipTitle/ToolTipTitle';
import Hint from '@atoms/Hint';
import { IOfferTechAnalysisFormData } from './IOfferTechAnalysisFormData';
import FormProviderControl from '@controls/FormProviderControl';
import HookFormProvider from '@controls/HookFormProvider';
import SelectControl from '@controls/SelectControl';
import TextareaControl from '@controls/TextareaControl';

export interface IOfferTechAnalysisProps {
    tableKey: string;
    isEdit: boolean;
    value: IOffer;
    demandId: string;
    onSubmit?: (value: IOfferTechAnalysisFormData) => void;
    onClose?: () => void;
}

const OfferTechAnalysis = ({
    tableKey,
    isEdit,
    value,
    demandId,
    onSubmit,
    onClose,
    ...props
}: IOfferTechAnalysisProps) => {
    const [error, setError] = useState<string>();
    const methods = useForm<IOfferTechAnalysisFormData>({
        mode: 'onBlur',
    });

    const { isSubmitting, isDirty } = methods.formState;

    const [acceptanceResult, setAcceptanceResult] = useState<number>();

    useEffect(() => {
        const defaultValue: IOfferTechAnalysisFormData = {
            acceptanceResult: value.acceptanceResult,
            acceptanceComment: value.acceptanceComment,
        };
        setAcceptanceResult(value.acceptanceResult);
        methods.reset(defaultValue);
    }, [value]);

    useEffect(() => {
        methods.trigger('acceptanceComment');
    }, [acceptanceResult]);

    const onClickClose = (e: any) => {
        onClose && onClose();
    };

    const onFormSubmit = async (form: IOfferTechAnalysisFormData) => {
        setError(undefined);
        return WinnerSelectionService.saveTableDemandOfferTechAnalysis(value.key, demandId, tableKey, form)
            .then((response) => {
                sendNotification({
                    message: 'Данные успешно сохранены',
                    variant: 'green',
                });
                onSubmit && onSubmit(form);
            })
            .catch((reason) => {
                setError(reason);
                console.log(reason);
            });
    };

    const results = useMemo(() => {
        return [
            { label: 'Соответствует', value: 1 },
            { label: 'Не соответствует', value: 0 },
        ] as IOption[];
    }, []);

    const errorLabel = error && (
        <div className="offer-ta__error">
            <Hint title={error} variant="red" />
        </div>
    );

    const ModalHeader = ({ onClose }: { onClose: (e: any) => void }) => (
        <div className={clsx('offer-ta-modal__header')}>
            <div className={clsx('offer-ta-modal__header-text')}>
                <span>{`Технический анализ предложения №${value.number || ''} (${value.name})`}</span>
            </div>
            <div className={clsx('offer-ta-modal__header-close')}>
                <Button
                    buttonType="text"
                    textColor="neutral"
                    size="xs"
                    aria-label="Закрыть окно"
                    onClick={onClose}
                    startAdornment={<MdClose size="24" />}
                />
            </div>
        </div>
    );

    const ModalFooter = () => (
        <div className={'modal-dlg-buttons'}>
            <Button
                type="submit"
                form="offer-ta-form"
                preloader={isSubmitting}
                className={clsx('offer-ta__button', 'offer-ta__save-button')}
                disabled={isSubmitting || !isDirty}
            >
                Сохранить
            </Button>
        </div>
    );

    return (
        <Modal size="xl" header={<ModalHeader onClose={onClickClose} />} footer={isEdit ? <ModalFooter /> : null}>
            {errorLabel}
            <FormProviderControl
                formMethods={methods}
                id="offer-ta-form"
                className="offer-ta__form"
                onSubmit={onFormSubmit}
            >
                <HookFormProvider controller={Controller}>
                    <div className="offer-ta__content">
                        <div className="offer-ta__row">
                            <SelectControl
                                label="Результат допуска"
                                name="acceptanceResult"
                                readOnly={true}
                                disabled={!isEdit}
                                highlightMenuItems={false}
                                options={results}
                                formState={methods.formState}
                                control={methods.control}
                                required={true}
                                rules={{ required: 'Обязательное поле' }}
                                setValue={(options) => {
                                    return options[0].value;
                                }}
                                getValue={(options: IOption[], value: any) => {
                                    return options.filter((x) => x.value === value);
                                }}
                                onChangeVal={(value) => {
                                    setAcceptanceResult(value[0].value);
                                }}
                            />
                        </div>
                        <div className="offer-ta__row">
                            <TextareaControl
                                label="Комментарий по соответствию"
                                name="acceptanceComment"
                                tooltip={
                                    <ToolTipTitle title="В случаях 'Не соответсвует' необходимо указать конкретные пункты ТЗ, комментарий будет внесен в обоснования критерия по соответствию ТЗ при наличии." />
                                }
                                readOnly={!isEdit}
                                disabled={!isEdit}
                                autoResize={true}
                                required={acceptanceResult === 0}
                                rules={{ required: acceptanceResult === 0 ? 'Обязательное поле' : false }}
                                formState={methods.formState}
                                control={methods.control}
                            />
                        </div>
                    </div>
                </HookFormProvider>
            </FormProviderControl>
        </Modal>
    );
};

export default OfferTechAnalysis;
