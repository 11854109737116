import InputNumberControl from '@controls/InputNumberControl';
import React from 'react';
import clsx from 'clsx';
import { IRatingScaleProps } from './IRatingScaleProps';

/**
 * ПСТЗ (численное значение вводимое вручную, не может быть выше 5, округление до сотых)
 * @param className
 * @param isEdit
 * @param methods
 * @constructor
 */
export const RatingScalePSTZ = ({ className, isEdit, methods }: IRatingScaleProps) => {
    return (
        <div className={clsx('offer-crit-rating-scale-pstz', className)}>
            <InputNumberControl
                label="Балл"
                name="offerCrit.score"
                required={true}
                readOnly={!isEdit}
                disabled={!isEdit}
                floatPoints={2}
                min={0}
                max={5}
                formState={methods.formState}
                control={methods.control}
                rules={{ required: 'Обязательное поле' }}
            />
            <span className={'offer-crit-details__formula-text'}>×</span>
            <InputNumberControl
                label="Вес"
                name="weight"
                required={true}
                readOnly={true}
                disabled={!isEdit}
                floatPoints={4}
                formState={methods.formState}
                control={methods.control}
                rules={{ required: 'Обязательное поле' }}
            />
            <span className={'offer-crit-details__formula-text'}>=</span>
            <InputNumberControl
                label="Рейтинг"
                name="offerCrit.rating"
                required={true}
                readOnly={true}
                disabled={!isEdit}
                floatPoints={4}
                formState={methods.formState}
                control={methods.control}
                rules={{ required: 'Обязательное поле' }}
            />
        </div>
    );
};
