import React, { useEffect, useState } from 'react';
import { IAttachSign, IAttachViewSignModel, ISignVerificationResult } from '@models/attaches/IAttachViewSignModel';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { CadespluginService } from '@services/CadespluginService';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import Moment from 'moment/moment';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { Button as GridButton, Column } from 'devextreme-react/data-grid';
import { IViewAttachSignProps } from './IViewAttachSignProps';

export const ViewAttachSignModal: React.FC<IViewAttachSignProps> = (props: IViewAttachSignProps) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [signsModel, setSignsModel] = useState<IAttachViewSignModel>();

    const [verificationResult, setVerificationResult] = useState<ISignVerificationResult>();
    const [signUnderVerification, setSignUnderVerification] = useState<IAttachSign>();

    const service = new AttachActionsService(props.docId, '-1');

    const verifyCheck = async (signId: string) => {
        setIsBusy(true);
        try {
            let serv = new CadespluginService();
            let fileWithContent = (await service.getAttachSignsWithContentBase64(props.docId, props.attachId, signId))
                .data;
            let verifyResult = await serv.verifyData(
                fileWithContent.attachContent,
                fileWithContent.signContent,
                fileWithContent.algorithm,
            );

            setVerificationResult(verifyResult);
        } finally {
            setIsBusy(false);
        }

        // if(verifyResult?.suceeded){
        //     alert('Подпись проверена успешно');

        // }else{
        //     alert('Ошибка проверки подписи: ' + errorString);
        // }
    };

    useEffect(() => {
        setIsBusy(true);

        service.getAttachSigns(props.attachId).then((res) => {
            setSignsModel(res.data);
            setIsBusy(false);
        });
    }, []);

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>Информация о подписях файла "{signsModel?.fileName}"</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props.completeHandler) props.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                        disabled={isBusy}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {verificationResult && (
                        <Hint
                            icon="info"
                            variant={verificationResult.suceeded ? 'green' : 'red'}
                            title="Проверка подписи"
                            onClose={() => {
                                setVerificationResult(undefined);
                            }}
                            maxWidth=""
                        >
                            <div>Владелец сертификата: {verificationResult.certOwner}</div>
                            <div>Серийный номер: {verificationResult.serialNumber}</div>
                            <div>
                                Период действия: {Moment(verificationResult.validFrom).format('DD.MM.YYYY HH:mm')} -{' '}
                                {Moment(verificationResult.validTo).format('DD.MM.YYYY HH:mm')}
                            </div>
                            <div>
                                Результат:{' '}
                                {verificationResult.suceeded
                                    ? 'Подпись успешно проверена'
                                    : 'Ошибка: ' + verificationResult.errorText}
                            </div>
                            <div>Документ подписал: {signUnderVerification?.signAuthor}</div>
                            <div>
                                Дата подписи:{' '}
                                {signUnderVerification?.date &&
                                    Moment(signUnderVerification?.date).format('DD.MM.YYYY HH:mm')}
                            </div>
                        </Hint>
                    )}

                    <DevExpressDataGrid
                        dataSource={signsModel?.signs}
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        noDataText={'Нет подписей'}
                        rowAlternationEnabled={true}
                        onCellHoverChanged={onCellHoverChanged}
                    >
                        <Column
                            key={`col_author`}
                            allowFiltering={false}
                            caption={'Автор подписи'}
                            dataField={'signAuthor'}
                            dataType={'string'}
                            visible={true}
                            allowSorting={false}
                        />

                        <Column
                            key={`col_date`}
                            allowFiltering={false}
                            caption={'Дата подписи'}
                            dataField={'date'}
                            dataType={'date'}
                            format={'dd.MM.yyyy HH:mm'}
                            visible={true}
                            allowSorting={false}
                        />

                        <Column type={'buttons'}>
                            <GridButton
                                text="Проверить"
                                onClick={(e: any) => {
                                    setSignUnderVerification(e.row.data);
                                    verifyCheck(e.row.data.key);
                                }}
                            />
                            <GridButton
                                text="Скачать"
                                onClick={(e: any) => {
                                    service.downloadAttachSigns(props.attachId, e.row.data.key);
                                }}
                            />
                        </Column>
                    </DevExpressDataGrid>
                </div>
            )}
        </Modal>
    );
};
