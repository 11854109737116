import './OfferRate.scss';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@atoms/Button';
import clsx from 'clsx';
import { MdClose } from 'react-icons/md';
import Modal from '@atoms/Modal';
import { IOffer } from '@models/winnerSelection/ITableDemand';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IOption } from '@/types';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { sendNotification } from '@molecules/Notifications';
import Hint from '@atoms/Hint';
import { IOfferRateFormData } from './IOfferRateFormData';
import FormProviderControl from '@controls/FormProviderControl';
import HookFormProvider from '@controls/HookFormProvider';
import SelectControl from '@controls/SelectControl';

export interface IOfferRateProps {
    tableKey: string;
    isEdit: boolean;
    value: IOffer;
    demandId: string;
    onSubmit?: (value: IOfferRateFormData) => void;
    onClose?: () => void;
    offerUnAcceptableHint?: string;
    lowCostPurchase?: boolean;
}

const OfferRate = ({
    tableKey,
    isEdit,
    value,
    demandId,
    onSubmit,
    onClose,
    offerUnAcceptableHint,
    lowCostPurchase = false,
    ...props
}: IOfferRateProps) => {
    const [error, setError] = useState<string>();
    const methods = useForm<IOfferRateFormData>({
        mode: 'onBlur',
    });

    const { isSubmitting, isDirty } = methods.formState;

    const watchRank = useWatch({ name: 'rank', control: methods.control });

    useEffect(() => {
        const defaultValue: IOfferRateFormData = {
            result: value.result,
            rank: value.rank,
        };
        methods.reset(defaultValue);
    }, [value]);

    useEffect(() => {
        if (isDirty) {
            methods.setValue('result', null, { shouldDirty: true });
        }
    }, [watchRank]);

    const onClickClose = (e: any) => {
        onClose && onClose();
    };

    const onFormSubmit = async (form: IOfferRateFormData) => {
        setError(undefined);
        return WinnerSelectionService.saveTableDemandOfferRate(value.key, demandId, tableKey, form)
            .then((response) => {
                sendNotification({
                    message: 'Данные успешно сохранены',
                    variant: 'green',
                });
                onSubmit && onSubmit(form);
            })
            .catch((reason) => {
                setError(reason);
                console.log(reason);
            });
    };

    const ranks = useMemo(() => {
        let ranks = [{ label: '', value: null }];
        ranks = ranks.concat(
            [...Array(10).keys()].map((val) => {
                return { label: `${val + 1}`, value: `${val + 1}` } as IOption;
            }),
        );

        return ranks;
    }, []);

    const resultsRankOptions: IOption[] = useMemo(() => {
        return watchRank === '1'
            ? [
                  { label: '', value: null },
                  { label: 'Основной', value: 'Основной' },
              ]
            : value.acceptanceResult !== 1
              ? [
                    { label: '', value: null },
                    { label: 'Одобренный после теста', value: 'Одобренный после теста' },
                ]
              : [
                    { label: '', value: null },
                    { label: 'Резервный', value: 'Резервный' },
                    { label: 'Одобренный после теста', value: 'Одобренный после теста' },
                ];
    }, [value.acceptanceResult, watchRank]);

    const resultVisible =
        (watchRank === '1' && value.acceptanceResult === 1) || (watchRank === '2' && lowCostPurchase === false);

    const errorLabel = error && (
        <div className="offer-rate__error">
            <Hint title={error} variant="red" />
        </div>
    );

    const offerUnAcceptableLabel = value.acceptanceResult !== 1 && offerUnAcceptableHint && (
        <div className={'offer-crit-details__hint'}>
            <Hint icon="info" title={offerUnAcceptableHint} variant="yellow" maxWidth="100%" />
        </div>
    );

    const ModalHeader = ({ onClose }: { onClose: (e: any) => void }) => (
        <div className={clsx('offer-rate-modal__header')}>
            <div className={clsx('offer-rate-modal__header-text')}>
                <span>{`${value.name} (предложение №${value.number || ''})`}</span>
            </div>
            <div className={clsx('offer-rate-modal__header-close')}>
                <Button
                    buttonType="text"
                    textColor="neutral"
                    size="xs"
                    aria-label="Закрыть окно"
                    onClick={onClose}
                    startAdornment={<MdClose size="24" />}
                />
            </div>
        </div>
    );

    const ModalFooter = () => (
        <div className={'modal-dlg-buttons'}>
            <Button
                type="submit"
                form="offer-rate-form"
                preloader={isSubmitting}
                className={clsx('offer-rate__button', 'offer-rate__save-button')}
                disabled={isSubmitting || !isDirty}
            >
                Сохранить
            </Button>
        </div>
    );

    return (
        <Modal size="m" header={<ModalHeader onClose={onClickClose} />} footer={isEdit ? <ModalFooter /> : null}>
            {errorLabel}
            {offerUnAcceptableLabel}
            <FormProviderControl
                formMethods={methods}
                id="offer-rate-form"
                className="offer-rate__form"
                onSubmit={onFormSubmit}
            >
                <HookFormProvider controller={Controller}>
                    <div className="offer-rate__content">
                        <div className="offer-rate__row">
                            <SelectControl
                                label="Место"
                                name="rank"
                                readOnly={true}
                                disabled={!isEdit}
                                highlightMenuItems={false}
                                options={ranks}
                                formState={methods.formState}
                                control={methods.control}
                                setValue={(options) => {
                                    return options[0].value;
                                }}
                                getValue={(options: IOption[], value: any) => {
                                    return options.filter((x) => x.value === value);
                                }}
                            />
                            {resultVisible ? (
                                <SelectControl
                                    label="Результат"
                                    name="result"
                                    readOnly={true}
                                    disabled={!isEdit}
                                    highlightMenuItems={false}
                                    options={resultsRankOptions}
                                    formState={methods.formState}
                                    control={methods.control}
                                    setValue={(options) => {
                                        return options[0].value;
                                    }}
                                    getValue={(options: IOption[], value: any) => {
                                        return options.filter((x) => x.value === value);
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                </HookFormProvider>
            </FormProviderControl>
        </Modal>
    );
};

export default OfferRate;
