import React, { useEffect } from 'react';
import './VersionPanel.scss';
import { TITLE_PREFIX } from '@/configuration';
import { useActions } from '@hooks/useActions';
import { useTypedSelector } from '@hooks/useTypedSelector';

const VersionPanel: React.FC = () => {
    const version = useTypedSelector((state) => state.appData.version);
    const { getVersion } = useActions();
    useEffect(() => {
        getVersion();
    }, []);

    return (
        <div className="version-container">
            <div className="version-container__app-name" data-testid="app-name">
                {TITLE_PREFIX}
            </div>
            <div className="version-container__app-version" data-testid="app-version">
                {version ?? ''}
            </div>
        </div>
    );
};

export default VersionPanel;
