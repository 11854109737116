import './ScrollGridToLeftButton.scss';
import React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Button from '@atoms/Button';
import clsx from 'clsx';

interface ScrollGridToLeftButtonProps {
    isScrolled: boolean;
    showBackButton: boolean;
    onScrollToLeft: (e: React.MouseEvent) => void;
    onScrollToPreviousPosition: (e: React.MouseEvent) => void;
    buttonPosition: {
        bottom?: number | string;
        left?: number | string;
        right?: number | string;
        top?: number | string;
    };
}

const ScrollGridToLeftButton: React.FC<ScrollGridToLeftButtonProps> = ({
    isScrolled,
    showBackButton,
    onScrollToLeft,
    onScrollToPreviousPosition,
    buttonPosition,
}) => {
    return (
        <div style={{ ...buttonPosition }} className="grid-scroll-button__wrapper">
            <div
                className={clsx(
                    'grid-scroll-button',
                    isScrolled && !showBackButton ? 'grid-scroll-button--visible' : 'grid-scroll-button--hidden',
                )}
            >
                <Button
                    className="grid-scroll-button__to-left"
                    aria-label="Scroll to left"
                    buttonType="light"
                    size="s"
                    startAdornment={<MdKeyboardArrowLeft size="20" />}
                    onClick={onScrollToLeft}
                >
                    В начало таблицы
                </Button>
            </div>
            <div
                className={clsx(
                    'grid-scroll-button',
                    showBackButton ? 'grid-scroll-button--visible' : 'grid-scroll-button--hidden',
                )}
            >
                <Button
                    className="grid-scroll-button__to-previous"
                    aria-label="Scroll to previous position"
                    buttonType="light"
                    size="s"
                    endAdornment={<MdKeyboardArrowRight size="20" />}
                    onClick={onScrollToPreviousPosition}
                >
                    Вернуться
                </Button>
            </div>
        </div>
    );
};

export default ScrollGridToLeftButton;
