import React, { FC } from 'react';
import './FormGroup.scss';
import clsx from 'clsx';

export interface IFormGroup {
    /** Дочерние элементы */
    children: React.ReactNode | React.ReactNode[];
    /** Имя */
    label?: React.ReactNode;
    /** Подсказка */
    tooltip?: React.ReactNode;
    /** Имя */
    labelSecondary?: React.ReactNode;
    /** Сообщение об ошибке */
    errorMessage?: React.ReactNode;
    /** Красная подсветка нижнего лейбла*/
    invalid?: boolean;
    /** disabled состояние верхнего лейбла */
    disabled?: boolean;
    /** Красная подсветка labelSecondary */
    invalidLabelSecondary?: boolean;
    /** Дополнительный класс */
    className?: string;
    /** Обязательность */
    required?: boolean;
    /** Выравнивание по верху */
    verticalBottomAlign?: boolean;
    hideErrorMessage?: boolean;
}

const FormGroup: FC<IFormGroup> = ({
    label,
    labelSecondary,
    tooltip,
    children,
    errorMessage,
    className = '',
    required = false,
    invalid = true,
    disabled = false,
    invalidLabelSecondary = true,
    verticalBottomAlign = false,
    hideErrorMessage = false,
}: IFormGroup) => {
    return (
        <div className={clsx('rf-form-group', className)}>
            <div className="rf-form-group_label__inner">
                {label && (
                    <div className={clsx('rf-form-group__label', disabled && 'rf-form-group__label--disabled')}>
                        {label && <span className="rf-form-group-fl">{label}</span>}
                        {required && <span className="rf-form-group-fl rf-form-group__required">*</span>}
                        {tooltip && <span className="rf-form-group-fl">{tooltip}</span>}
                        {!!labelSecondary && (
                            <span
                                className={clsx(
                                    'rf-form-group__label-secondary',
                                    invalidLabelSecondary && 'rf-form-group__message_no_invalid',
                                )}
                            >
                                {labelSecondary}
                            </span>
                        )}
                    </div>
                )}
            </div>
            {verticalBottomAlign ? (
                <div className={clsx('rf-form-group__inner', 'rf-form-group__verticalbottom')}>
                    {!hideErrorMessage && errorMessage && (
                        <p className={clsx('rf-form-group__message', !invalid && 'rf-form-group__message_no-invalid')}>
                            {errorMessage}
                        </p>
                    )}
                    <div className={clsx('rf-form-group__ctl')}>{children}</div>
                </div>
            ) : (
                <div className={clsx('rf-form-group__inner', 'rf-form-group__verticaltop')}>
                    <div className={clsx('rf-form-group__ctl')}>{children}</div>
                    {!hideErrorMessage && errorMessage && (
                        <p className={clsx('rf-form-group__message', !invalid && 'rf-form-group__message_no-invalid')}>
                            {errorMessage}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default FormGroup;
