import React, { JSX, useEffect, useRef, useState } from 'react';
import DataGrid from 'devextreme-react/data-grid';
import ScrollGridToLeftButton from '@hooks/useDataGridScrollToLeftButton/ScrollGridToLeftButton';

interface IUseDataGridScrollToLeftButtonOptions {
    disabled?: boolean;
    pollInterval?: number;
    buttonPosition?: {
        bottom?: number | string;
        left?: number | string;
        right?: number | string;
        top?: number | string;
    };
}

interface IUseDataGridScrollToLeftButtonResult {
    containerProps: {
        ref: React.RefObject<HTMLDivElement | null>;
        style: React.CSSProperties;
    };
    buttonContainerElement: JSX.Element | null;
    setGridRef: (ref: DataGrid | null) => void;
}

export const useDataGridScrollToLeftButton = ({
    disabled = false,
    pollInterval = 200,
    buttonPosition = { bottom: '44px', left: '200px' },
}: IUseDataGridScrollToLeftButtonOptions = {}): IUseDataGridScrollToLeftButtonResult => {
    const containerRef = useRef<HTMLDivElement>(null);
    const gridRef = useRef<DataGrid | null>(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [showBackButton, setShowBackButton] = useState(false);
    const previousScrollPosition = useRef<number>(0);

    const setGridRef = (ref: DataGrid | null) => {
        gridRef.current = ref;
    };

    const scrollGridToLeft = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!gridRef.current) return;

        const instance = gridRef.current.instance;
        if (!instance) return;

        const scrollable = instance.getScrollable();
        if (!scrollable) return;

        previousScrollPosition.current = scrollable.scrollLeft();
        scrollable.scrollTo({ left: 0 });
        setShowBackButton(true);
    };

    // Scroll grid to the previous position
    const scrollGridToPreviousPosition = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!gridRef.current) return;

        const instance = gridRef.current.instance;
        if (!instance) return;

        const scrollable = instance.getScrollable();
        if (!scrollable) return;

        scrollable.scrollTo({ left: previousScrollPosition.current });
        setShowBackButton(false);
    };

    // Monitor scroll position
    useEffect(() => {
        if (disabled) return;

        let scrollCheckInterval: number;
        let isSetUp = false;

        const checkScroll = () => {
            if (!gridRef.current) return;

            const instance = gridRef.current.instance;
            if (!instance) return;

            const scrollable = instance.getScrollable();
            if (!scrollable) return;

            const currentScrollLeft = scrollable.scrollLeft();
            setIsScrolled(currentScrollLeft > 0);
        };

        const setupScrollMonitoring = () => {
            if (isSetUp || !gridRef.current) return;

            const instance = gridRef.current.instance;
            if (!instance) return;

            const scrollable = instance.getScrollable();
            if (!scrollable) return;

            scrollCheckInterval = window.setInterval(checkScroll, pollInterval);
            isSetUp = true;
        };

        setupScrollMonitoring();

        const initTimeout = setTimeout(() => {
            setupScrollMonitoring();
        }, 500);

        return () => {
            clearTimeout(initTimeout);
            clearInterval(scrollCheckInterval);
        };
    }, [pollInterval]);

    const buttonContainerElement = (
        <ScrollGridToLeftButton
            isScrolled={isScrolled}
            showBackButton={showBackButton}
            onScrollToLeft={scrollGridToLeft}
            onScrollToPreviousPosition={scrollGridToPreviousPosition}
            buttonPosition={buttonPosition}
        />
    );

    return {
        containerProps: {
            ref: containerRef,
            style: { position: 'relative' },
        },
        buttonContainerElement,
        setGridRef,
    };
};
