import './FormBuilder.scss';
import 'moment/locale/ru';

import { UseFormReturn } from 'react-hook-form';
import { IDocumentBlock, IRows } from '@models/Forms/IForms';
import Row from './controls/Row/Row';
import { IFieldElem } from '@models/IFormData';
import { IUniqueKey } from './interfaces/IUniqueKey';
import { IDocumentScheme } from '@models/document/IDocumentScheme';
import Preloader from '@atoms/Preloader';
import React from 'react';

export interface IFormBuilder<TFieldValues extends object = object> extends IUniqueKey {
    block?: IDocumentBlock;
    rows?: IRows;
    formMethods: UseFormReturn<TFieldValues>;
    setError: (errors?: string[]) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
    docId?: string;
    docScheme?: IDocumentScheme;
}

const FormBuilder = <TFieldValues extends object = object>({
    block,
    rows,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    docId,
    uniqueKey,
    docScheme,
    ...props
}: IFormBuilder<TFieldValues>) => {
    return (
        <div className="form-container" {...props}>
            {block ? (
                block.rows.row.map((item, i) => (
                    <Row
                        row={item}
                        fields={fields}
                        formMethods={formMethods}
                        docId={docId}
                        setError={setError}
                        isEdit={isEdit}
                        isNew={isNew}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                        uniqueKey={uniqueKey}
                        docScheme={docScheme}
                    />
                ))
            ) : rows ? (
                rows.rows.row.map((item, i) => (
                    <Row
                        row={item}
                        fields={fields}
                        formMethods={formMethods}
                        docId={docId}
                        setError={setError}
                        isEdit={isEdit}
                        isNew={isNew}
                        key={uniqueKey + i}
                        includedFields={includedFields}
                        uniqueKey={uniqueKey}
                        docScheme={docScheme}
                    />
                ))
            ) : rows === undefined ? (
                <Preloader size="l" position="static" />
            ) : null}
        </div>
    );
};

export default FormBuilder;
