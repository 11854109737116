import './OfferCritDetails.scss';
import React, { useEffect, useState } from 'react';
import Button from '@atoms/Button';
import clsx from 'clsx';
import { MdClose } from 'react-icons/md';
import Modal from '@atoms/Modal';
import { IOfferCrit, ITableDemandItem, RatingScale } from '@models/winnerSelection/ITableDemand';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { sendNotification } from '@molecules/Notifications';
import Hint from '@atoms/Hint';
import { round } from 'mathjs';
import { IOfferCritDetailsFormData } from './IOfferCritDetailsFormData';
import FormProviderControl from '@controls/FormProviderControl';
import HookFormProvider from '@controls/HookFormProvider';
import TextareaControl from '@controls/TextareaControl';
import { RatingScalePirSmr, RatingScalePSTZ, RatingScaleSimTum, RatingScaleTMC } from './RatingScales';

export interface IOfferCritDetailsProps {
    tableKey: string;
    isEdit: boolean;
    demandId: string;
    offerId: string;
    disabledHint?: string;
    editButtonName?: string | null;
    value: IOfferCrit;
    rowData: ITableDemandItem;
    onSubmit?: (value: IOfferCritDetailsFormData) => void;
}

const checkCommentRequired = (scale: RatingScale, score: number | null, isInfoCrit: boolean) => {
    if (isInfoCrit) return false;
    if (score === null) return false;
    return score < 3.0;
};

const OfferCritDetails = ({
    tableKey,
    isEdit,
    disabledHint,
    editButtonName,
    demandId,
    offerId,
    value,
    rowData,
    onSubmit,
    ...props
}: IOfferCritDetailsProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [commentRequired, setCommentRequired] = useState(() => {
        return checkCommentRequired(rowData.ratingScale, value?.score ?? null, rowData.isInfoCrit);
    });

    const methods = useForm<IOfferCritDetailsFormData>({
        mode: 'onBlur',
    });

    const { isSubmitting, isDirty } = methods.formState;

    const watchCalcValues = useWatch({ name: ['offerCrit.score', 'weight'], control: methods.control });

    useEffect(() => {
        let values = methods.getValues();
        if (values.offerCrit && values.offerCrit.score !== null && values.weight !== null) {
            // Считаем новое значение рейтинга
            let newRating = round(values.offerCrit.score * values.weight, 4);
            methods.setValue('offerCrit.rating', newRating, { shouldDirty: true });
        }

        const isCommentRequired = checkCommentRequired(
            rowData.ratingScale,
            values?.offerCrit?.score ?? null,
            rowData.isInfoCrit,
        );
        setCommentRequired(isCommentRequired);
    }, [watchCalcValues]);

    useEffect(() => {
        const defaultValue: IOfferCritDetailsFormData = {
            offerCrit: value,
            critId: rowData.critId,
            weight: rowData.critWeight,
        };
        methods.reset(defaultValue);
    }, [value, showModal]);

    const onClick = (e: any) => {
        setShowModal(!showModal);
    };

    const onFormSubmit = async (form: IOfferCritDetailsFormData) => {
        setError(undefined);
        return WinnerSelectionService.saveTableDemandOfferCrit(offerId, demandId, tableKey, form)
            .then((response) => {
                sendNotification({
                    message: 'Данные успешно сохранены',
                    variant: 'green',
                });
                setShowModal(!showModal);
                onSubmit && onSubmit(form);
            })
            .catch((reason) => {
                setError(reason);
                console.log(reason);
            });
    };

    const errorLabel = error && (
        <div className="offer-crit-details__error">
            <Hint title={error} variant="red" />
        </div>
    );

    const ModalHeader = () => (
        <div className={clsx('offer-crit-details-modal__header')}>
            <div className={clsx('offer-crit-details-modal__header-text')}>
                <span>{`${rowData.critName}`}</span>
            </div>
            <div className={clsx('offer-crit-details-modal__header-close')}>
                <Button
                    buttonType="text"
                    textColor="neutral"
                    size="xs"
                    aria-label="Закрыть окно"
                    onClick={() => {
                        setShowModal(false);
                    }}
                    startAdornment={<MdClose size="24" />}
                />
            </div>
        </div>
    );

    const ModalFooter = () => (
        <div className={'modal-dlg-buttons'}>
            <Button
                type="submit"
                form="offer-crit-details-form"
                preloader={isSubmitting}
                className={clsx('offer-crit-details__button', 'offer-crit-details__save-button')}
                disabled={isSubmitting || !isDirty}
            >
                Сохранить
            </Button>
        </div>
    );

    const RatingScale = () => {
        switch (rowData.ratingScale) {
            case 'simtum':
                return <RatingScaleSimTum className="offer-crit-details__column" isEdit={isEdit} methods={methods} />;
            case 'pirsmr':
                return <RatingScalePirSmr className="offer-crit-details__column" isEdit={isEdit} methods={methods} />;
            case 'pstz':
                return <RatingScalePSTZ className="offer-crit-details__column" isEdit={isEdit} methods={methods} />;
            case 'tmcws':
            default:
                return <RatingScaleTMC className="offer-crit-details__column" isEdit={isEdit} methods={methods} />;
        }
    };

    return (
        <>
            <div className={clsx('offer-crit-details')} onClick={onClick}>
                <Button type="button" aria-label="Показать детали" buttonType={'secondary'} onClick={onClick}>
                    {editButtonName ?? 'Показать детали'}
                </Button>
            </div>
            {showModal ? (
                <Modal size="xl" header={<ModalHeader />} footer={isEdit ? <ModalFooter /> : null}>
                    {errorLabel}
                    {!isEdit && disabledHint && (
                        <div className={'offer-crit-details__hint'}>
                            <Hint icon="info" title={disabledHint} variant="yellow" maxWidth="100%" />
                        </div>
                    )}
                    <FormProviderControl
                        formMethods={methods}
                        id="offer-crit-details-form"
                        className="offer-crit-details__form"
                        onSubmit={onFormSubmit}
                    >
                        <HookFormProvider controller={Controller}>
                            <div className="offer-crit-details__content">
                                {!rowData.isInfoCrit ? (
                                    <div className="offer-crit-details__row">
                                        <RatingScale />
                                    </div>
                                ) : null}
                                <div className="offer-crit-details__row">
                                    <TextareaControl
                                        label={rowData.isInfoCrit ? 'Комментарий' : 'Обоснование'}
                                        name="offerCrit.description"
                                        readOnly={!isEdit}
                                        disabled={!isEdit}
                                        required={commentRequired}
                                        autoResize={true}
                                        rules={{
                                            required: {
                                                value: commentRequired,
                                                message: 'Обязательное поле',
                                            },
                                        }}
                                        formState={methods.formState}
                                        control={methods.control}
                                    />
                                </div>
                            </div>
                        </HookFormProvider>
                    </FormProviderControl>
                </Modal>
            ) : null}
        </>
    );
};

export default OfferCritDetails;
