import React from 'react';

import './StatusBar.scss';
import { MdCheckCircle } from 'react-icons/md';
import clsx from 'clsx';
import { FaRegCircleDot } from 'react-icons/fa6';
import { FaDotCircle } from 'react-icons/fa';

export type StatusSize = 'big' | 'small';

export interface IStatusBarItem {
    status: string;
    title: string;
}

export interface IStatusBarParentItem {
    status: string;
    title: string;
    children: IStatusBarItem[];
}

export interface IStatusBarProps {
    items?: IStatusBarParentItem[];
    smallTitle?: boolean;
}

const StatusBar: React.FC<IStatusBarProps> = ({ items, smallTitle }: IStatusBarProps) => {
    const renderIcon = (status: string, size: StatusSize) => {
        const className = `icon--${status}`;
        switch (status) {
            case 'Done':
                return size === 'big' ? (
                    <MdCheckCircle className={className} size="32" />
                ) : (
                    <MdCheckCircle className={className} size="24" />
                );
            case 'Active':
                return size === 'big' ? (
                    <FaRegCircleDot className={className} size="26" />
                ) : (
                    <FaRegCircleDot className={className} size="20" />
                );
            default:
                return size === 'big' ? (
                    <FaDotCircle className={className} size="26" />
                ) : (
                    <FaDotCircle className={className} size="20" />
                );
        }
    };

    const classTitle = smallTitle ? 'statusbar-level-title small' : 'statusbar-level-title normal';

    return items ? (
        <div className="statusbar-container">
            {items.map((item, index, coll) => {
                return (
                    <div className="statusbar-level" key={`statusbar-level-${index}`}>
                        <div className="statusbar-level-graph" key={`statusbar-level-graph-${index}`}>
                            <div className="statusbar-graph-icon" key={`statusbar-graph-icon-${index}`}>
                                {renderIcon(item.status, 'big')}
                            </div>
                            {coll.length - 1 > index && (
                                <div
                                    className={clsx('statusbar-graph-line', `statusbar-graph-line--${item.status}`)}
                                    key={`statusbar-graph-line-${index}`}
                                />
                            )}
                        </div>
                        <div className={classTitle} key={`statusbar-level-title-${index}`}>
                            {item.title}
                        </div>
                        <div className="statusbar-level-subitems" key={`statusbar-level-subitems-${index}`}>
                            {item.children.map((item, index) => {
                                return (
                                    <div className="statusbar-subitem" key={`statusbar-subitem-${index}`}>
                                        <div className="statusbar-subitem-icon" key={`statusbar-subitem-icon-${index}`}>
                                            {renderIcon(item.status, 'small')}
                                        </div>
                                        <div
                                            className="statusbar-subitem-title"
                                            key={`statusbar-subitem-title-${index}`}
                                        >
                                            {item.title}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    ) : (
        <></>
    );
};

export default StatusBar;
