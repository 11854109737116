import './CopyAndEditAttachesExecutor.scss';
import React, { useEffect, useRef, useState } from 'react';
import {
    ICopyAndEditAttachesAddResponse,
    ICopyAndEditAttachesData,
    ICopyAndEditAttachesResponse,
    ICopyAndEditAttachesScheme,
} from '@models/attaches/ICopyAndEditAttaches';
import { CopyAndEditAttachesService } from '@services/CopyAndEditAttachesService';
import { DataGrid } from 'devextreme-react/data-grid';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import { CopyAndEditAttachFilesBlock } from '@molecules/AttachFilesBlock/CopyAndEditAttachFilesBlock';
import { ICopyAndEditAttachesExecutorProps } from './ICopyAndEditAttachesExecutorProps';

export const CopyAndEditAttachesExecutorModal: React.FC<ICopyAndEditAttachesExecutorProps> = ({
    docId,
    actionKey,
    displayName,
    okButtonText,
    cancelButtonText,
    completeHandler,
}) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [errorText, setErrorText] = useState<string>();
    const [scheme, setScheme] = useState<ICopyAndEditAttachesScheme>();
    const [data, setData] = useState<ICopyAndEditAttachesData>();
    const [selected, setSelected] = useState<ICopyAndEditAttachesAddResponse[]>([]);
    const [existAttaches, setExistAttaches] = useState<Map<string, string[]>>(new Map());

    useEffect(() => {
        setIsBusy(true);
        CopyAndEditAttachesService.fetchScheme().then((x) => {
            setScheme(x.data);
        });
        CopyAndEditAttachesService.fetchData(docId, '-1', '').then((x) => {
            setData(x.data);
        });
    }, []);

    useEffect(() => {
        if (scheme && data) {
            const map = new Map();
            var list = data?.data.filter((x) => x.isExistAttach);
            list.forEach((item) => {
                map.set(item.key, item.existAttachKeys);
            });

            setExistAttaches(map);
            setIsBusy(false);
        }
    }, [scheme, data]);

    const gridRef = useRef<DataGrid>(null);

    const onFilesSelected = (files: ICopyAndEditAttachesAddResponse[]) => {
        setSelected(files);
    };

    const onSubmit = () => {
        let addAttaches = selected.filter((file) => !existAttaches.has(file.key));

        let deleteAttaches = new Set<string>(); // Перечень attachId без галочки (должны быть удалены)
        let recoveryAttaches = new Set<string>(); // Перечень attachId с галочкой (нужно оставить)
        existAttaches.forEach((vals, key) => {
            if (selected.findIndex((file) => file.key == key) == -1) {
                vals.forEach(deleteAttaches.add, deleteAttaches);
            } else {
                vals.forEach(recoveryAttaches.add, recoveryAttaches);
            }
        });
        // Если для attachId стоит хотя бы одна галочка, то его стоит оставить
        recoveryAttaches.forEach(deleteAttaches.delete, deleteAttaches);

        let response = {
            addAttaches: addAttaches,
            deleteAttaches: Array.from(deleteAttaches),
        } as ICopyAndEditAttachesResponse;

        setIsBusy(true);
        let executor = new DocumentExecutionService(docId);
        executor
            .copyAndEditAttaches(actionKey, response)
            .then(() => {
                setIsBusy(false);
                if (completeHandler) completeHandler(true);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    };

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={'max'}
            header={
                <div className={clsx('box')}>
                    <div>{displayName}</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (completeHandler) completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        size="s"
                        aria-label={okButtonText}
                        onClick={() => {
                            onSubmit();
                        }}
                        disabled={isBusy}
                    >
                        {okButtonText}
                    </Button>

                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={cancelButtonText}
                        onClick={() => {
                            if (completeHandler) completeHandler(false);
                        }}
                        disabled={isBusy}
                    >
                        {cancelButtonText}
                    </Button>
                </div>
            }
        >
            {isBusy ? (
                <Preloader size="m" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}
                    {scheme && data && (
                        <CopyAndEditAttachFilesBlock scheme={scheme} data={data!} onFilesSelected={onFilesSelected} />
                    )}
                </div>
            )}
        </Modal>
    );
};
