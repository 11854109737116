import React, { FC, useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import clsx from 'clsx';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import './AbookpickerModal.scss';
import { IAbookTab } from '@models/Forms/IForms';
import { IAddressBookData } from '@models/addressbook/IAddressBookData';
import { ITab } from '@/types';
import Tabs from '../../Tabs';
import ListModeControl from './ModeControls/ListModeControl';
import TreeModeControl from './ModeControls/TreeModeControl';
import AbookExternalSearchModal from './AbookExternalSearchModal';
import { IAbookpickerRefActions } from '../IAbookpickerRefActions';
import AbookpickerItem from './AbookpickerItem';
import { AddressBookService } from '@services/AddressBookService';
import { getLoadOptionsQuery } from '@utils/loadOptionsConverter';
import { IAbookSettings } from './IAbookSettings';

export interface ISelectFromAbookDialogProp extends IAbookSettings {
    onCloseModal: () => void;
    onSubmitModal: (data: IAddressBookData[]) => Promise<void>;
}

const AbookpickerModal: FC<ISelectFromAbookDialogProp> = (p: ISelectFromAbookDialogProp) => {
    const [selectedRowItems, setSelectedRowItems] = useState<IAddressBookData[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const [tabs, setTabs] = useState<ITab[]>();
    const [showExternalSearch, setShowExternalSearch] = useState<boolean>(false);
    const [tabSettings, setTabSettings] = useState<IAbookTab>();

    const controlRef = useRef<IAbookpickerRefActions>(null);

    useEffect(() => {
        if (p.tabsSettings) {
            let _tabs: ITab[] = [];
            p.tabsSettings?.forEach((item) => {
                _tabs.push({
                    label: item.name,
                    handler: () => setTabSettings(item),
                });
            });
            setTabSettings(p.tabsSettings![0]);
            setTabs(_tabs);
        }
    }, [p.tabsSettings]);

    useEffect(() => {
        if (tabSettings) {
            setSelectedKeys([]);
            setSelectedRowItems([]);
        }
    }, [tabSettings]);

    const getParams = (options: any) => {
        let params = '?';
        params += getLoadOptionsQuery(options);

        if (p.getFormValues) {
            params += '&formValues=' + p.getFormValues();
        } else {
            params += '&formValues=' + p.formValues;
        }

        return params;
    };

    const getSelectedKeys = async (selected: number[]) => {
        let result: number[] = [];

        await AddressBookService.fetchListSelectedKeys(tabSettings!.cache, selected).then((response) => {
            result = response.data;
        });

        return result;
    };

    const toggleShowExternalSearch = () => {
        setShowExternalSearch(!showExternalSearch);
    };

    const ModalFooter = ({
        onClose,
        onSubmit,
    }: {
        onClose: () => void;
        onSubmit: (data: IAddressBookData[]) => Promise<void>;
    }) => (
        <div className={'abookModal_footer'}>
            {p.externalSearch && (
                <div className="right">
                    <Button onClick={toggleShowExternalSearch} buttonType="light" size="s">
                        {p.externalSearch.buttonName}
                    </Button>
                </div>
            )}
            <Button
                onClick={async () => {
                    await onSubmit(selectedRowItems);
                    onClose();
                }}
                size="s"
            >
                Выбрать
            </Button>
            <Button onClick={onClose} buttonType="light" size="s">
                Отменить
            </Button>
        </div>
    );

    const getControl = (mode: string) => {
        if (tabSettings) {
            switch (mode) {
                case 'tree': {
                    return (
                        <TreeModeControl
                            currentTab={tabSettings}
                            onSelectedRowItems={(val: IAddressBookData[]) => {
                                setSelectedRowItems(val);
                            }}
                            onSelectedKeys={(value: number[]) => {
                                setSelectedKeys(value);
                            }}
                            getParams={getParams}
                            selected={p.selected}
                            title={p.title}
                            isMultiple={p.isMultiple}
                            formValues={p.formValues}
                            controlRef={controlRef}
                        />
                    );
                }

                default:
                    return (
                        <ListModeControl
                            currentTab={tabSettings}
                            onSelectedRowItems={(val: IAddressBookData[]) => {
                                setSelectedRowItems(val);
                            }}
                            onSelectedKeys={(value: number[]) => {
                                setSelectedKeys(value);
                            }}
                            getParams={getParams}
                            getSelectedKeys={async (selected: number[]) => {
                                return await getSelectedKeys(selected);
                            }}
                            selected={p.selected}
                            title={p.title}
                            isMultiple={p.isMultiple}
                            formValues={p.formValues}
                            controlRef={controlRef}
                        />
                    );
            }
        }
    };

    return (
        <Modal
            className={clsx('abookModal', 'dx-dropdowneditor-overlay', {
                'abookModal--single-select': !p.isMultiple,
            })}
            size="content"
            header={
                <div className={clsx('box')}>
                    <div>
                        <div>{p.title}</div>
                    </div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                p.onCloseModal();
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={<ModalFooter onClose={p.onCloseModal} onSubmit={p.onSubmitModal} />}
        >
            <div className="abook-container">
                {p.showChips && selectedRowItems.length > 0 && (
                    <div className="rf-file-input__attachments">
                        {selectedRowItems.map((item: IAddressBookData, index: number) => (
                            <AbookpickerItem
                                key={index}
                                item={item}
                                showRemoveIcon={true}
                                onRemove={() => {
                                    controlRef.current?.unSelect(item);
                                }}
                            />
                        ))}
                    </div>
                )}
                <div className="abook-tabs">{tabs && tabs.length > 0 && <Tabs list={tabs!} />}</div>
                <div className="abook-grid">{tabSettings && getControl(tabSettings.mode)}</div>
            </div>
            {showExternalSearch && (
                <AbookExternalSearchModal
                    settings={p.externalSearch!}
                    onCloseModal={() => {
                        toggleShowExternalSearch();
                    }}
                    onSubmitModal={() => {
                        controlRef.current?.reset();
                    }}
                    currentTab={tabSettings!}
                    getParams={getParams}
                />
            )}
        </Modal>
    );
};

export default AbookpickerModal;
