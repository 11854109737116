import React, { createRef, ReactNode, RefObject, useContext, useRef } from 'react';
import { NavLink } from 'react-router';
import './List.scss';
import { IListElement } from '@/types';
import { MenuContext } from '../MenuContext';
import clsx from 'clsx';
import Tooltip from '../../Tooltip';
import { ITooltipProps } from '../../Tooltip/Tooltip';
import { MdOpenInNew } from 'react-icons/md';
import Button from '@atoms/Button';

interface IListProps {
    /** Элементы меню */
    list: IListElement[];
    /** Цвет tooltip */
    tooltipBackground?: ITooltipProps['background'];
    className?: string;
}

const List: React.FC<IListProps> = ({ list, tooltipBackground = 'white', className }: IListProps) => {
    /** Ссылки на элементы списка */
    const refs = useRef<RefObject<HTMLDivElement | HTMLAnchorElement | null>[]>([]);

    const onElementClick = (e: React.MouseEvent, li: IListElement) => {
        e.stopPropagation();
        !li.url && e.preventDefault();
        li.handler && li.handler();
        onClose && onClose();
    };

    const { onClose } = useContext(MenuContext);

    const checkOverflow = (index: number) => {
        const el = refs.current[index].current;

        if (!el) {
            return;
        }

        return el?.clientWidth < el.scrollWidth || el?.clientHeight < el.scrollHeight;
    };

    const renderLabel = (index: number, label: ReactNode) => {
        return checkOverflow(index) ? (
            <Tooltip background={tooltipBackground} position="bottom">
                <span>{label}</span>
                <>{label}</>
            </Tooltip>
        ) : (
            <>{label}</>
        );
    };

    const renderNewTabButton = (li: IListElement) => {
        return li.showOpenInNewTabButton ? (
            <Tooltip background={tooltipBackground} position="bottom">
                <Button
                    buttonType="icon"
                    size="xxs"
                    aria-label="Открыть в новой вкладке"
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        li.newTabHandler && li.newTabHandler();
                    }}
                    startAdornment={<MdOpenInNew size="20" />}
                />
                Открыть в новой вкладке
            </Tooltip>
        ) : (
            <></>
        );
    };

    const listElementJSX = list?.map((li: IListElement, i: number) => {
        const disabledClass = li.disabled ? 'rf-list__element--disabled' : '';
        const separatedClass = li.separated ? 'rf-list__element--separated' : '';

        if (!refs.current[i]) {
            refs.current[i] = createRef();
        }

        return (
            <li className="rf-li" key={li.value || i}>
                {li.separated && <div className={clsx('rf-list__separator', 'rf-list__separator--top')} />}
                {li.url ? (
                    <NavLink
                        //innerRef={refs.current[i] as RefObject<HTMLAnchorElement>}
                        to={li.url}
                        style={li.style}
                        className={clsx('rf-list__element', disabledClass, separatedClass)}
                        data-testid={`rf-list-element-${li.value || i}`}
                        onClick={(e: React.MouseEvent) => onElementClick(e, li)}
                    >
                        {renderLabel(i, li.label)}
                    </NavLink>
                ) : (
                    <div
                        style={li.style}
                        ref={refs.current[i] as RefObject<HTMLDivElement>}
                        className={clsx(
                            'rf-list__element',
                            disabledClass,
                            separatedClass,
                            li.isTitle && 'rf-list__element--title',
                        )}
                        data-testid={`rf-list-element-${li.value || i}`}
                        onClick={(e: React.MouseEvent) => !(li.isTitle || li.preventClick) && onElementClick(e, li)}
                        title={li.tooltip}
                    >
                        <div className={clsx('rf-list__element-label')}>{renderLabel(i, li.label)}</div>
                        <div className={clsx('rf-list__new-tab-button')}>{renderNewTabButton(li)}</div>
                    </div>
                )}
                {li.separatedBottom && <div className={clsx('rf-list__separator', 'rf-list__separator--bottom')} />}
            </li>
        );
    });

    // -------------------------------------------------------------------------------------------------------------------

    return (
        <ul className={clsx('rf-list', className)} data-testid="rf-list">
            {listElementJSX}
        </ul>
    );
};

export default List;
