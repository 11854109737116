import './ListModeControl.scss';
import DataGrid, { Column, FilterRow, Paging, Scrolling, Selection, Sorting } from 'devextreme-react/data-grid';
import React, { FC, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { DictionariesService } from '@services/DictionariesService';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IDictFilter } from '@models/Forms/IForms';
import { ColumnFixing } from 'devextreme-react/tree-list';
import { simulateMouseClick } from '@utils/helpers';
import { IDictSettings } from '@atoms/Dictpicker/IDictSettings';
import DataSource from 'devextreme/data/data_source';
import {
    filterGridRowsByScript,
    filterResponseVoid,
    getColumnDataTypeByFieldDataType,
    getColumnFilterOperationsByColumnDataType,
    onCellHoverChanged,
    setDataValueAttr,
} from '@utils/dataGridUtils';
import { getLoadOptionsQuery } from '@utils/loadOptionsConverter';
import { IDictpickerRefActions } from '@atoms/Dictpicker/IDictpickerRefActions';
import { ValueType } from '@/types/ValueType';
import { CellPreparedEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import { ConfigService } from '@/configuration/services/configService';
import { validateLoadedData } from '@utils/helpersDictPicker';
import { useUserScripts } from '@hooks/useUserScripts';
import clsx from 'clsx';

export interface IListModeControlProp extends IDictSettings {
    onSelectedRowItems: (value: IDictionaryData[]) => void;
    onSelectedKeys: (value: string[]) => void;
    dictAttributes: string[];
    filterResponse: IDictFilter;
    controlRef: React.Ref<IDictpickerRefActions>;
}

const ListModeControl: FC<IListModeControlProp> = (p: IListModeControlProp) => {
    const config = ConfigService.get();
    const applyFilterOnClick = config.application.applyGridFilterOnClick ?? false;

    const [filterRemoteQuery, setFilterRemoteQuery] = useState<any[]>([]);

    useImperativeHandle(p.controlRef, () => ({
        reset: () => {
            gridRef?.current?.instance.refresh();
        },
        setSelected: (items: string[]) => {
            gridRef?.current?.instance.selectRows(items, false);
            gridRef?.current?.instance.refresh();
        },
    }));

    /**
     * TODO Построение фильтрующего условия для отправки на сервер
     * */
    useEffect(() => {
        if (p.filterResponse && p.filterResponse.filter && p.filterResponse.filter != '') {
            const filter = JSON.parse(p.filterResponse.filter.replace(/\'/g, '"'));

            let conditions: any[] = [];
            if (filter.codes) {
                let column = filter.columnName ? filter.columnName : 'code';
                let codes = filter.codes as string[];
                /**
                 * TODO Научить сервер обрабатывать запросы вида ["!", ["value", "=", 3]]
                 * */
                let operand = filter.isShow ? '=' : '!=';
                let binary = filter.isShow ? 'or' : 'and';
                codes.forEach((code) => {
                    if (conditions.length > 0) {
                        conditions.push(binary);
                    }
                    conditions.push([column, operand, code]);
                });
            }
            setFilterRemoteQuery(conditions);
        }
    }, [p.filterResponse]);

    const getUrlParam = (loadOptions: any) => {
        let params = '?';
        params += getLoadOptionsQuery(loadOptions);
        return params;
    };

    const gridSource = useMemo(() => {
        return new DataSource<IDictionaryData, string>({
            /**
             * TODO Перенести фильтрацию из метода load в пост-обработку (корректно работают внутренние счётчики)
             * */
            //postProcess: postProcessFunction,
            filter: p.isFormData || filterRemoteQuery.length == 0 ? null : filterRemoteQuery,
            requireTotalCount: true,
            key: 'code',
            async load(loadOptions: any) {
                let params = getUrlParam(loadOptions);
                params += '&formValues=' + (await p.getFormValuesAsync());
                if (p.predicatesCache) {
                    params += '&predicates=' + p.predicatesCache;
                }
                if (p.isFormData) {
                    return DictionariesService.getGridFormdataItems(p.docId!, p.dictName, params).then((response) => {
                        validateLoadedData(response.data.data, p.loadedDataValidators);
                        if (
                            p.filterResponse &&
                            (p.filterResponse.script || (p.filterResponse.filter && p.filterResponse.filter != ''))
                        ) {
                            let _data: IDictionaryData[] = [];
                            /**
                             * TODO Добавить серверную фильтрацию для псевдосправочников
                             * */
                            _data = filterResponseVoid(response.data.data, p.filterResponse);
                            if (p.filterResponse?.script) {
                                _data = filterGridRowsByScript(_data, p.filterResponse?.script);
                            }
                            response.data.data = _data;
                            response.data.totalCount = _data.length;
                        }
                        let exDs = p.getExternalDataSource();
                        if (exDs.length > 0) {
                            let data: IDictionaryData[] = [];
                            for (let index = 0; index < response.data.data.length; index++) {
                                const element = response.data.data[index];
                                if (
                                    exDs.findIndex((x) => {
                                        return x.code == element.code;
                                    }) == -1
                                ) {
                                    data.push(element);
                                }
                            }
                            exDs.forEach((element) => {
                                data.push(element);
                            });
                            response.data.totalCount = data.length;
                            response.data.data = data;
                        }

                        return response.data;
                    });
                } else {
                    if (p.joinedDictionaries && p.joinedDictionaries.length > 0) {
                        let joinDictsParams = JSON.stringify(p.joinedDictionaries);
                        params += `&joinedDictionaries=${joinDictsParams}`;
                    }

                    return DictionariesService.getGridItems(p.dictName, params).then((response) => {
                        validateLoadedData(response.data.data, p.loadedDataValidators);
                        if (p.filterResponse) {
                            let _data: IDictionaryData[] = response.data.data;
                            /**
                             * Используется серверная фильтрация
                             * */
                            //_data = filterResponseVoid(response.data.data, filterResponse);
                            if (p.filterResponse?.script) {
                                _data = filterGridRowsByScript(_data, p.filterResponse?.script);
                            }
                            response.data.totalCount =
                                response.data.totalCount - (response.data.data.length - _data.length);
                            response.data.data = _data;
                        }
                        return response.data;
                    });
                }
            },
        });
    }, [p.dictName, p.filterResponse, filterRemoteQuery]);

    // -------------------------------------------------------------------------------------------------------------------
    // Initial items select
    // -------------------------------------------------------------------------------------------------------------------
    const [isFirstLoadReady, setIsFirstLoadReady] = useState<boolean>(false);

    useEffect(() => {
        if (isFirstLoadReady) {
            setIsFirstLoadReady(false);
        }
    }, [p.dictName]);

    useEffect(() => {
        if (isFirstLoadReady) {
            if (p.selected) {
                const grid = gridRef.current?.instance;
                if (grid) {
                    grid.selectRows(p.selected, true).then(() => {
                        grid.repaint();
                    });
                }
            }
        }
    }, [isFirstLoadReady]);

    const onContentReady = useCallback(() => {
        setIsFirstLoadReady(true);
    }, []);

    // -------------------------------------------------------------------------------------------------------------------

    const checkIsRowSelectable = useCallback(
        (data?: IDictionaryData) => {
            if (p.selectableLevels) {
                if (p.selectableLevels === 'last') {
                    return !data?.hasChild;
                }
                if (p.selectableLevels === 'first') {
                    return data?.parent === '';
                }
            }
            return true;
        },
        [p.selectableLevels],
    );

    const onGridEditorPreparing = useCallback(
        (e: any) => {
            if (applyFilterOnClick && e.parentType === 'filterRow') {
                e.editorOptions.onEnterKey = function () {
                    // применение фильтра по нажатию Enter
                    simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
                };
            }
        },
        [applyFilterOnClick],
    );

    const onSelectionChanged = useCallback(
        (e: any) => {
            let property = 'code';
            if (!p.isMultiple) {
                if (e.currentSelectedRowKeys.length > 0) {
                    let item = e.selectedRowsData.pop();
                    e.component.selectRows([item[property]], false);
                    p.onSelectedKeys([item[property]]);
                    p.onSelectedRowItems([item!]);
                } else if (e.selectedRowKeys.length == 0) {
                    p.onSelectedKeys([]);
                    p.onSelectedRowItems([]);
                }
            } else {
                p.onSelectedKeys(e.selectedRowKeys);
                p.onSelectedRowItems(e.selectedRowsData);
            }
        },
        [p],
    );

    const gridRef = useRef<DataGrid>(null);

    const codeColumn = useMemo(() => {
        let attr = p.gridAttribute?.attrs.find((a) => {
            return a.key === 'code';
        });
        if (attr) {
            return (
                <Column
                    key={'code'}
                    dataField={'code'}
                    caption={attr.name}
                    width={attr.width}
                    defaultSortOrder={attr.sortOrder}
                    cssClass={attr.cssClasses?.join(' ')}
                />
            );
        }
        if (p.gridAttribute == undefined) {
            return <Column key={'code'} dataField={'code'} caption="Код" width="auto" />;
        }
        return <></>;
    }, [p.gridAttribute]);

    const cellUrlRender = useCallback(
        (param: any) => {
            if (p.isFormData && param.data.url && param.data.urlAttributes.indexOf(param.column.caption) !== -1) {
                return (
                    <a target="_blank" href={param.data.url} rel="noreferrer">
                        {param.value}
                    </a>
                );
            } else {
                return <>{param.value}</>;
            }
        },
        [p.isFormData],
    );

    // сортируем столбцы в порядке как они заданы в шаблоне
    const gridAttributesKeys = useMemo(() => {
        return p.gridAttribute?.attrs.map((val) => val.key) ?? [];
    }, [p.gridAttribute?.attrs]);

    const sortedDictAttributes = useMemo(() => {
        return [...p.dictAttributes]?.sort(
            (a, b) =>
                (gridAttributesKeys.indexOf(a) === -1 ? Infinity : gridAttributesKeys.indexOf(a)) -
                (gridAttributesKeys.indexOf(b) === -1 ? Infinity : gridAttributesKeys.indexOf(b)),
        );
    }, [gridAttributesKeys, p.dictAttributes]);

    const schemeColumns = useMemo(() => {
        return sortedDictAttributes.map((schemeColumn, _) => {
            const index = p.dictAttributes.indexOf(schemeColumn);
            const attr = p.gridAttribute?.attrs.find((a) => a.key === schemeColumn);
            if (attr !== undefined) {
                return (
                    <Column
                        key={index}
                        allowFiltering={true}
                        caption={attr?.name}
                        dataField={`fields[${index}].value`}
                        dataType={attr?.type ? attr?.type : 'string'}
                        width={attr?.width}
                        visible={true}
                        allowSorting={true}
                        defaultSortOrder={attr.sortOrder}
                        filterOperations={getColumnFilterOperationsByColumnDataType(
                            getColumnDataTypeByFieldDataType((attr?.type ? attr?.type : 'string') as ValueType),
                        )}
                        encodeHtml={true}
                        trueText={attr?.type && attr?.type === 'boolean' ? '1' : undefined}
                        falseText={attr?.type && attr?.type === 'boolean' ? '0' : undefined}
                        showEditorAlways={attr?.type && attr?.type === 'boolean' ? true : undefined}
                        //calculateCellValue={attr?.type && attr?.type === 'boolean' ? calcBoolCell : undefined}
                        cellRender={attr?.type && attr?.type !== 'boolean' ? undefined : cellUrlRender}
                        cssClass={attr?.cssClasses?.join(' ')}
                        headerCellRender={(p) => {
                            return (
                                <div className="title-column-box">
                                    <div
                                        className={clsx(
                                            'title-column-caption',
                                            attr?.headerNoEllipsis && 'title-column-caption-noEllipsis',
                                        )}
                                    >
                                        {p.column.caption}
                                    </div>
                                </div>
                            );
                        }}
                    />
                );
            }
            if (p.gridAttribute === undefined) {
                return (
                    <Column
                        key={index}
                        allowFiltering={true}
                        caption={schemeColumn}
                        dataField={`fields[${index}].value`}
                        dataType={'string'}
                        visible={true}
                        allowSorting={true}
                        filterOperations={['contains']}
                        encodeHtml={true}
                        cellRender={cellUrlRender}
                    />
                );
            }
            return <React.Fragment key={index} />;
        });
    }, [cellUrlRender, p.dictAttributes, p.gridAttribute, sortedDictAttributes]);

    const totalWidth = useMemo(() => {
        return 100 + (p.gridAttribute?.attrs.reduce((sum, x) => sum + x.width, 0) || 0);
    }, [p.gridAttribute?.attrs]);

    const onRowClick = useCallback((e: any) => {
        if (checkIsRowSelectable(e.data)) {
            if (e.component.getSelectedRowKeys().indexOf(e.key) > -1) {
                e.component.deselectRows([e.key]);
            } else {
                e.component.selectRows([e.key], true);
            }
        }
    }, []);

    const isPaginationEnabled = useMemo(() => !(p.loadMode && p.loadMode === 'all'), [p.loadMode]);

    // -------------------------------------------------------------------------------------------------------------------
    // Пользовательские скрипты
    // -------------------------------------------------------------------------------------------------------------------

    const { onRowPreparedScript, onCellPreparedScript } = useUserScripts(
        p.userScripts?.onRowPrepared,
        p.userScripts?.onCellPrepared,
    );

    const onRowPrepared = useCallback(
        async (row: RowPreparedEvent<any, string>) => {
            if (row.rowType === 'data' && onRowPreparedScript) {
                try {
                    eval(onRowPreparedScript);
                } catch (error) {
                    console.log('onRowPrepared. Ошибка выполнения скрипта. Подробности ниже.');
                    console.error(error);
                }
            }
        },
        [onRowPreparedScript],
    );

    const onCellPrepared = useCallback(
        async (cell: CellPreparedEvent<any, string>) => {
            if (cell.rowType === 'data') {
                setDataValueAttr(cell);
            }

            if (cell.rowType === 'data' && onCellPreparedScript) {
                try {
                    eval(onCellPreparedScript);
                } catch (error) {
                    console.log('onCellPrepared. Ошибка выполнения скрипта. Подробности ниже.');
                    console.error(error);
                }
            }
        },
        [onCellPreparedScript],
    );

    // -------------------------------------------------------------------------------------------------------------------

    if (sortedDictAttributes) {
        return (
            <DevExpressDataGrid
                width={totalWidth}
                key={'grid'}
                dataSource={gridSource}
                hoverStateEnabled={true}
                columnHidingEnabled={false}
                columnMinWidth={30}
                showColumnHeaders={true}
                columnAutoWidth={false}
                allowColumnReordering={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                noDataText={'Нет строк'}
                ref={gridRef}
                onSelectionChanged={onSelectionChanged}
                onEditorPreparing={onGridEditorPreparing}
                //defaultSelectedRowKeys={p.selected}
                remoteOperations={!(p.useClientSideDataProcessing ?? false)}
                onRowClick={onRowClick}
                onCellHoverChanged={onCellHoverChanged}
                onContentReady={onContentReady}
                onRowPrepared={onRowPrepared}
                onCellPrepared={onCellPrepared}
            >
                <Selection mode="multiple" allowSelectAll={p.isMultiple} selectAllMode="allPages" />
                {codeColumn}
                {schemeColumns}
                <FilterRow
                    showOperationChooser={true}
                    visible={true}
                    applyFilter={applyFilterOnClick ? 'onClick' : 'auto'}
                />
                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" showSortIndexes={true} />
                {isPaginationEnabled && <Scrolling mode="virtual" />}
                <Paging enabled={isPaginationEnabled} defaultPageSize={10} />
            </DevExpressDataGrid>
        );
    }

    return <></>;
};

export default ListModeControl;
