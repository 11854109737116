import './CreateAttachVersionExecutor.scss';
import React, { useEffect, useState } from 'react';
import { IFileData } from '@/types';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import InputFile from '@atoms/InputFile';
import { MAX_FILE_SIZE } from '@utils/constants';
import { AttachActionsService } from '@services/actions/AttachActionsService';
import { ICreateAttachVersionProps } from './ICreateAttachVersionProps';

export const CreateAttachVersionModal: React.FC<ICreateAttachVersionProps> = (props: ICreateAttachVersionProps) => {
    const [isBusy, setIsBusy] = useState<boolean>();
    const [selectedFiles, setSelectedFiles] = useState<IFileData[]>();
    const [fileName, setFileName] = useState<string>('');
    const [acceptExtension, setAcceptExtension] = useState<string>('*');

    useEffect(() => {
        let rowData = Array.isArray(props.rowData) ? props.rowData[0] : props.rowData;
        let fileName = rowData.fields['AttachmentFileName'] ?? rowData.fields['AttachmentTitle'] ?? '';
        let splitFileName = fileName.split('.');
        let ext: string;
        if (!splitFileName[splitFileName.length - 1]) {
            ext = '*';
        } else {
            ext = `.${splitFileName[splitFileName.length - 1]}`;
        }

        setFileName(fileName);
        setAcceptExtension(ext);
    }, [props.rowData]);

    const setFile = (file: IFileData[]) => {
        setSelectedFiles(file);
    };

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>Создать версию</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props.completeHandler) props.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        size="s"
                        aria-label={props.okButtonText}
                        disabled={!selectedFiles || selectedFiles.length === 0 || isBusy}
                        onClick={() => {
                            setIsBusy(true);
                            let service = new AttachActionsService(props.docId, '-1');
                            service.createAttachVersion(selectedFiles?.[0].file, props.attachId).then(() => {
                                setIsBusy(false);
                                if (props.completeHandler) props.completeHandler(true);
                            });
                        }}
                    >
                        {props.okButtonText}
                    </Button>
                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                        disabled={isBusy}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    <div>
                        <InputFile
                            className="CreateAttachVersion__SelectFilesInput"
                            setFile={setFile}
                            placeholderButton={`Выберите версию файла с устройства`}
                            placeholderDropzone={`Перетащите версию файла ${fileName} в эту область`}
                            maxSize={MAX_FILE_SIZE}
                            multiple={false}
                            accept={acceptExtension}
                        />
                    </div>
                </div>
            )}
        </Modal>
    );
};
