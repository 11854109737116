import React, { ChangeEvent, FC, HTMLProps, ReactNode, useCallback, useEffect, useState } from 'react';
import './Timepicker.scss';
import InputMask from '@mona-health/react-input-mask';

import Input from '@atoms/Input';
import Button from '@atoms/Button';
import TimeElement from './TimeElement';
import Menu from '../Menu';
import clsx from 'clsx';
import { MdClose, MdPending } from 'react-icons/md';
import { getTime } from '@utils/helpersDatePicker';

export interface ITimepickerProps extends Omit<HTMLProps<HTMLInputElement>, 'ref'> {
    /** Css класс */
    className?: string;
    /** Заблокирован выбор или нет
     * @default false
     */
    disabled?: boolean;
    /** Переводит инпут в невалидный статус
     * @default false
     */
    readOnly?: boolean;
    /** Переводит инпут в невалидный статус
     * @default false
     */
    invalid?: boolean;
    /** Начальное значение
     * @example 12:00
     */
    initialValue?: string;
    /** Функция при изменении значения */
    onChangeValue?: (value: string, id: string) => void;
    /** Минимальное значение
     * @default 00:00
     */
    minTime?: string;
    /** Максимальное значение
     * @default 24:00
     */
    maxTime?: string;
    /**
     *  Безрамочный укороченый вариант
     * @default false
     */
    isMinified?: boolean;
    /**
     *  Формат значения без секунд
     * @default true
     */
    isNoSecFormat?: boolean;
    /**
     *  Отображение иконки в конце строки
     * @default true
     *  */
    showTailIcon?: boolean;

    children?: ReactNode | ReactNode[];

    testid?: string;
}

// FIXME: Form elements must have labels
// FIXME: Elements must have sufficient color contrast
const Timepicker: FC<ITimepickerProps> = ({
    className,
    initialValue = '',
    disabled = false,
    readOnly = false,
    invalid = false,
    onChangeValue,
    minTime = '00:00',
    maxTime = '24:00',
    isMinified = false,
    isNoSecFormat = true,
    showTailIcon = true,
    children = null,
    testid,
    ...props
}: ITimepickerProps) => {
    const [time, setTime] = useState(initialValue);

    useEffect(() => {
        // onChangeValue && onChangeValue(initialValue, props.id || "");
        setTime(initialValue);
    }, [initialValue]);

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value;
            setTime(val);

            if (val && !~val.indexOf('_')) {
                onChangeValue && onChangeValue(val, props.id || '');
            }
        },
        [onChangeValue, props.id],
    );

    const updateTime = useCallback(
        (newTime: string) => {
            setTime(newTime);
            onChangeValue && onChangeValue(newTime, props.id || '');
        },
        [onChangeValue, props.id],
    );

    const onClearValue = useCallback(() => setTime(''), []);

    const content = (
        <div tabIndex={-1} className="rf-timepicker__time-elmnt-wrapper">
            <TimeElement
                updateTime={updateTime}
                value={time}
                min={minTime}
                max={maxTime}
                isNoSecFormat={isNoSecFormat}
            />
        </div>
    );
    const emptyValue = !time || time === '' || time === '__:__' || time === '__:__:__';

    const getMask = useCallback(() => {
        const [hours] = getTime(time);
        const startsWithTwo = hours?.startsWith('2');

        let format = [/[0-2]/, startsWithTwo ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/];

        if (!isNoSecFormat) {
            format.push(':', /[0-5]/, /[0-9]/);
        }

        return format;
    }, [time]);

    return (
        <div
            className={clsx(
                'rf-timepicker__wrapper',
                disabled && 'rf-timepicker--disabled',
                emptyValue && 'rf-timepicker--empty',
                isMinified && 'rf-timepicker-minified',
                readOnly && 'rf-timepicker--readonly',
            )}
            data-testid={testid ? `timepicker-${testid}` : undefined}
        >
            <Menu position="bottom" disabled={readOnly} content={content}>
                {!children && (
                    <InputMask
                        mask={getMask()}
                        value={time}
                        disabled={disabled}
                        autoComplete="off"
                        alwaysShowMask={true}
                        readOnly={readOnly}
                        onChange={onChange}
                    >
                        <Input
                            isBorder={!isMinified}
                            data-testid="rf-timepicker__input"
                            disabled={disabled}
                            readOnly={readOnly}
                            autoComplete="off"
                            invalid={invalid}
                            className={className}
                            {...props}
                            endAdornment={
                                children ||
                                (showTailIcon && (
                                    <div className="rf-timepicker__menu">
                                        <Button
                                            buttonType="text"
                                            className="rf-timepicker__btn"
                                            disabled={disabled}
                                            aria-label={emptyValue ? 'Выбрать время' : 'Сбросить'}
                                            endAdornment={
                                                emptyValue ? (
                                                    <MdPending
                                                        size="16"
                                                        className={clsx(
                                                            'rf-timepicker__icon',
                                                            'rf-timepicker__icon-time',
                                                        )}
                                                    />
                                                ) : (
                                                    <MdClose
                                                        size="16"
                                                        className={clsx(
                                                            'rf-timepicker__icon',
                                                            'rf-timepicker__icon-close',
                                                        )}
                                                    />
                                                )
                                            }
                                            onClick={!emptyValue ? onClearValue : undefined}
                                        />
                                    </div>
                                ))
                            }
                        />
                    </InputMask>
                )}
            </Menu>
        </div>
    );
};

export default Timepicker;
