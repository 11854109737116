import React, { JSX, useCallback } from 'react';

import './GridDetails.scss';
import Preloader from '@atoms/Preloader';
import { IDataGridDescriptionResponse } from '@models/response/IDataGridDescriptionResponse';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import {
    getColumnDataTypeByFieldDataType,
    getColumnFilterOperationsByColumnDataType,
    onCellHoverChanged,
} from '@utils/dataGridUtils';
import {
    Column,
    ColumnChooser,
    ColumnChooserSearch,
    ColumnChooserSelection,
    DataGrid,
    FilterRow,
    Grouping,
    GroupPanel,
    MasterDetail,
    Pager,
    Paging,
    Selection,
    Sorting,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { Link } from 'react-router';
import DisplayField from '@atoms/DisplayField/DisplayField';
import { OptionChangedEvent } from 'devextreme/ui/data_grid';
import Button from '@atoms/Button';
import { MdMenu } from 'react-icons/md';
import CustomStore from 'devextreme/data/custom_store';
import { IDataGridService } from '@services/IDataGridService';
import { ConfigService } from '@/configuration/services/configService';

export interface IGridDetailsProps {
    key: string;
    scheme: IDataGridDescriptionResponse;
    gridRef: React.RefObject<DataGrid<any, any> | null>;
    service: IDataGridService;
    customTemplates?: JSX.Element[];
    linkPrefix?: string;
    data: any;
    onRowPrepared?: (e: any) => void;
    onOptionChanged: (e: OptionChangedEvent<any, string>) => void;
    onRowClick?: (e: any) => void;
    onContextMenuPreparing?: (e: any) => void;
    detailSection: (param: any) => JSX.Element;
}

const allowedPageSizes: (number | 'all' | 'auto')[] = [5, 10, 20, 'all'];

const GridDetails: React.FC<IGridDetailsProps> = ({
    key,
    scheme,
    gridRef,
    service,
    customTemplates,
    linkPrefix,
    data,
    onRowPrepared,
    onOptionChanged,
    onRowClick,
    onContextMenuPreparing,
    detailSection,
}: IGridDetailsProps) => {
    const config = ConfigService.get();
    const applyFilterOnClick = config.application.applyGridFilterOnClick ?? false;

    const detailStore = (scheme: IDataGridDescriptionResponse, mainParam: any, detailParam: any) => {
        return new CustomStore({
            key: scheme?.dataSource.store.key,
            load(options: any) {
                const columns = gridRef.current?.instance.getVisibleColumns();
                if (columns?.length === 0) return [];

                let columnsIndexes: string[] = [];
                columns?.forEach((column) => {
                    if (
                        column?.visibleIndex == null ||
                        column.dataField == 'actionColumn' ||
                        column.dataField == 'emptyColumn'
                    )
                        return;

                    columnsIndexes.push((column.visibleIndex + 1).toString());
                });

                let loadOptions = {
                    options: options,
                    fields: columnsIndexes.join(','),
                };
                if (detailParam) {
                    return service.fetchDetailData(mainParam, detailParam, loadOptions).then((res) => {
                        return res.data;
                    });
                } else {
                    return service.fetchData(mainParam, loadOptions).then((res) => {
                        return res.data;
                    });
                }
            },
        });
    };

    const onDetailsOptionChanged = (e: OptionChangedEvent<any, string>) => {
        /**
         * Обработка по логике основного грида
         */
        onOptionChanged(e);
    };

    const onDetailsCellClick = (e: any) => {
        if (e.column.name == 'actionColumn') {
            e.event?.stopImmediatePropagation();
        }
    };

    return (
        <DevExpressDataGrid
            onRowPrepared={onRowPrepared}
            dataSource={detailStore(scheme!, data, key)}
            remoteOperations={true}
            hoverStateEnabled={true}
            columnHidingEnabled={false}
            columnMinWidth={30}
            showColumnHeaders={scheme?.options.showColumnHeaders}
            columnAutoWidth={scheme?.options.columnAutoWidth}
            allowColumnReordering={scheme?.options.allowColumnReordering}
            allowColumnResizing={scheme?.options.allowColumnResizing}
            columnResizingMode="widget"
            noDataText={scheme?.options.noDataText}
            rowAlternationEnabled={scheme?.options.rowAlternationEnabled}
            onCellClick={onDetailsCellClick}
            onRowClick={useCallback((e: any) => {
                if (!scheme?.options.isMaster) {
                    onRowClick?.(e);
                }
            }, [])}
            onContextMenuPreparing={onContextMenuPreparing}
            className={scheme?.options.cssClasses.join(' ')}
            onCellHoverChanged={onCellHoverChanged}
            onOptionChanged={onDetailsOptionChanged}
        >
            {scheme?.options.isMaster && (scheme.detailsForm || scheme.detailsGrid) && (
                <MasterDetail enabled={true} render={detailSection} />
            )}

            {scheme?.columns?.map((schemeColumn, i) => {
                return (
                    <Column
                        key={`col_${key}_${i}`}
                        /* Настройка width ломает настройку грида columnResizingMode="widget" */
                        width={schemeColumn.width ? schemeColumn.width : undefined}
                        minWidth={
                            schemeColumn.minWidth && schemeColumn.minWidth > 0
                                ? schemeColumn.width.toString().endsWith('vw')
                                    ? 5
                                    : schemeColumn.minWidth
                                : undefined
                        }
                        allowFiltering={schemeColumn.allowFiltering}
                        caption={schemeColumn.header}
                        dataField={schemeColumn.field}
                        dataType={getColumnDataTypeByFieldDataType(schemeColumn.dataType)}
                        alignment={schemeColumn.alignment}
                        showInColumnChooser={schemeColumn.showInColumnChooser}
                        visible={!schemeColumn.defaultHide}
                        allowGrouping={schemeColumn.allowGrouping}
                        sortIndex={schemeColumn.sortIndex ?? undefined}
                        sortOrder={schemeColumn.sortOrder}
                        //colIndexId={ schemeColumn.colIndexId}
                        //allowFiltering={schemeColumn.dataField !== "actionColumn"}
                        allowSorting={
                            schemeColumn.field !== 'actionColumn' &&
                            schemeColumn.field !== 'emptyColumn' /*schemeColumn.allowSorting*/
                        }
                        filterOperations={getColumnFilterOperationsByColumnDataType(
                            getColumnDataTypeByFieldDataType(schemeColumn.dataType),
                        )}
                        encodeHtml={true}
                        cellTemplate={
                            schemeColumn.dxGridCellTemplate
                                ? schemeColumn.dxGridCellTemplate
                                : schemeColumn.field === 'actionColumn'
                                  ? 'dxGridRowMenuCellTemplateAjax'
                                  : 'dxGridFieldCellTemplate'
                        }
                    />
                );
            })}

            <Template
                name="dxGridFieldCellTemplate"
                render={
                    //js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
                    function (data: any) {
                        let { value } = data;

                        let key = '';
                        if (data.rowType === 'data') {
                            if (data.data.documentKey) {
                                key = data.data.documentKey;
                            } else if (data.data.key) {
                                key = data.data.key;
                            }
                        }

                        // Для корректного отображения переносов строк заменяем переносы каретки на аналог из html
                        if (typeof value === 'string') {
                            value = value.replaceAll('\n', '<br />');
                        }

                        let col = scheme?.columns?.find((x) => x.field === data.column.dataField);
                        let ids = key.split('/');
                        let linkedDocId = ids.length > 1 ? ids[1] : ids[0];
                        return linkPrefix ? (
                            <Link to={`${linkPrefix}/${linkedDocId}`}>
                                <DisplayField type={col?.dataType!} value={value} />
                            </Link>
                        ) : (
                            <DisplayField type={col?.dataType!} value={value} />
                        );
                    }
                }
            />
            {customTemplates}

            <Template
                name="dxGridRowMenuCellTemplateAjax"
                render={
                    //js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
                    function (data: any) {
                        let key = data['key'];
                        let loading: boolean = false;

                        return loading ? (
                            <Preloader size="s" />
                        ) : (
                            <Button
                                id={key}
                                buttonType="text"
                                textColor="neutral"
                                size="xxxs"
                                aria-label="Действия"
                                onClick={() => {
                                    loading = true;
                                }}
                                startAdornment={<MdMenu size="18" />}
                            />
                        );
                    }
                }
            />

            <Selection
                allowSelectAll={scheme?.selection.allowSelectAll}
                mode={scheme?.selection.mode}
                selectAllMode={scheme?.selection.selectAllMode}
                showCheckBoxesMode={scheme?.selection.showCheckBoxesMode}
            />
            <ColumnChooser enabled={true} mode={'select'} height={600}>
                <ColumnChooserSelection allowSelectAll={true} selectByClick={true} />
                <ColumnChooserSearch enabled={true} />
            </ColumnChooser>

            <FilterRow visible={true} applyFilter={applyFilterOnClick ? 'onClick' : 'auto'} />

            <GroupPanel
                visible={scheme?.groupPanel.visible}
                allowColumnDragging={scheme?.groupPanel.allowColumnDragging}
            />

            <Grouping autoExpandAll={scheme?.groupPanel.visible} />

            <Pager
                showInfo={scheme?.pager.showInfo}
                showPageSizeSelector={scheme?.pager.showPageSizeSelector}
                visible={scheme?.paging.enabled}
                showNavigationButtons={true}
                displayMode={'full'}
                allowedPageSizes={allowedPageSizes}
            />

            <Paging enabled={scheme?.paging.enabled} defaultPageSize={scheme?.paging.pageSize} />

            <Sorting mode="multiple" />
        </DevExpressDataGrid>
    );
};

export default GridDetails;
