import './CopyAttachmentsExecutor.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    IAttachmentToCopy,
    IAttachmentToCopyExtended,
    ICopyAttachmentsResult,
} from '@models/document/actionsExecution/ICopyAttachmentsModel';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import { Column, DataGrid, Selection } from 'devextreme-react/data-grid';
import Modal from '@atoms/Modal';
import clsx from 'clsx';
import Button from '@atoms/Button';
import { MdClose } from 'react-icons/md';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { onCellHoverChanged } from '@utils/dataGridUtils';
import { ICopyAttachmentsExecutorProps } from './ICopyAttachmentsExecutorProps';

export const CopyAttachmentsExecutorModal: React.FC<ICopyAttachmentsExecutorProps> = (
    props: ICopyAttachmentsExecutorProps,
) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();

    const [attachmentsModel, setAttachmentsModel] = useState<IAttachmentToCopyExtended[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const service = new DocumentExecutionService(props.docId);
    const gridRef = useRef<DataGrid>(null);

    useEffect(() => {
        setIsBusy(true);
        service
            .getCopyAttachmentsModel(props.actionKey)
            .then((res) => {
                setAttachmentsModel(res.data.attachments);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    }, []);

    const onSelectionChanged = useCallback((e: any) => {
        setSelectedKeys(e.selectedRowKeys);
    }, []);

    return (
        <Modal
            className={clsx('modal-dlg-container')}
            size={props.modalSize}
            header={
                <div className={clsx('box')}>
                    <div>{props.title}</div>
                    <div className={clsx('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                if (props.completeHandler) props.completeHandler(false);
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="modal-dlg-buttons">
                    <Button
                        disabled={selectedKeys.length === 0 || isBusy}
                        size="s"
                        aria-label={props.okButtonText}
                        onClick={() => {
                            setIsBusy(true);

                            let selectedRows = gridRef?.current?.instance.getSelectedRowsData();
                            let selectedAttachments =
                                selectedRows?.map((row) => {
                                    return {
                                        docId: row.docId,
                                        attachId: row.attachId,
                                        categoryName: row.categoryName,
                                    } as IAttachmentToCopy;
                                }) ?? [];

                            let copyAttachmentsResult: ICopyAttachmentsResult = {
                                attachments: selectedAttachments,
                            };
                            service
                                .copyAttachments(props.actionKey, copyAttachmentsResult)
                                .then(() => {
                                    setIsBusy(false);
                                    if (props.completeHandler) props.completeHandler(true);
                                })
                                .catch((error) => setErrorText(error))
                                .finally(() => {
                                    setIsBusy(false);
                                });
                        }}
                    >
                        {props.okButtonText}
                    </Button>

                    <Button
                        buttonType="light"
                        size="s"
                        aria-label={props.cancelButtonText}
                        onClick={() => {
                            if (props.completeHandler) props.completeHandler(false);
                        }}
                        disabled={isBusy}
                    >
                        {props.cancelButtonText}
                    </Button>
                </div>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <DevExpressDataGrid
                        dataSource={attachmentsModel}
                        remoteOperations={true}
                        hoverStateEnabled={true}
                        columnHidingEnabled={false}
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        noDataText={'Файлы отсутствуют'}
                        rowAlternationEnabled={true}
                        onSelectionChanged={onSelectionChanged}
                        ref={gridRef}
                        onCellHoverChanged={onCellHoverChanged}
                    >
                        <Selection
                            mode="multiple"
                            allowSelectAll={true}
                            selectAllMode={'allPages'}
                            showCheckBoxesMode={'always'}
                        />
                        <Column
                            key={`col_filename`}
                            caption={'Имя файла'}
                            dataField={'displayName'}
                            dataType={'string'}
                            allowSorting={false}
                            allowHeaderFiltering={false}
                        />
                        <Column
                            key={`col_category`}
                            caption={'Категория'}
                            dataField={'categoryName'}
                            dataType={'string'}
                            allowSorting={false}
                            allowHeaderFiltering={false}
                        />
                    </DevExpressDataGrid>
                </div>
            )}
        </Modal>
    );
};
