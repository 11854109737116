import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteNames } from '@/router/router.types';
import { useTypedSelector } from '@hooks/useTypedSelector';
import { useActions } from '@hooks/useActions';
import './Navbar.scss';
import logo from '@images/logo.svg';
import Button from '@atoms/Button';
import Menu from '@atoms/Menu';
import {
    MdAccountCircle,
    MdClose,
    MdDescription,
    MdEditNotifications,
    MdGroup,
    MdGroupAdd,
    MdLogout,
    MdNotifications,
    MdOutlineExpandLess,
    MdOutlineExpandMore,
    MdSettings,
    MdSupervisorAccount,
    MdSupportAgent,
} from 'react-icons/md';
import { IListElement, IOption } from '@/types';
import { Link, useNavigate } from 'react-router';
import SupportModal from '@atoms/SupportModal/SupportModal';
import UserPanel from '../UserPanel/UserPanel';
import List from '@atoms/Menu/List';
import VersionPanel from '../VersionPanel/VersionPanel';
import Avatar from '@atoms/Avatar';
import Modal from '@atoms/Modal/Modal';
import { UserService } from '@services/UserService';
import Select from '@atoms/Select/Select';
import { IAddressBookItem } from '@models/addressbook/IAddressBookItem';
import Preloader from '@atoms/Preloader/Preloader';
import { jwtDecode } from 'jwt-decode';
import clsx from 'clsx';
import DebugSettings from '@molecules/Navbar/DebugSettings/DebugSettings';
import { IDebugSettings } from '@models/IDebugSettings';
import { useLocalStorage } from 'usehooks-ts';
import { ConfigService } from '@/configuration/services/configService';
import GlobalSearchInput from '@molecules/GlobalSearchInput';

const Navbar: FC = () => {
    const [showSupportModal, setShowSupportModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const user = useTypedSelector((state) => state.auth.user);
    const userAvatar = useTypedSelector((state) => state.userAvatar);
    const { getUserAvatar, logout } = useActions();

    const [isOrgEditMode, setIsOrgEditMode] = useState<boolean>();
    const [isSavingDepartment, setIsSavingDepartment] = useState<boolean>();
    const [selectedDep, setSelectedDep] = useState<IOption | undefined>();
    const [departmentsList, setDepartmentsList] = useState<IOption[]>([]);
    const { updateUserDepartment } = useActions();

    const config = ConfigService.get();

    useEffect(() => {
        getUserAvatar();
    }, []);

    useEffect(() => {
        if (isOrgEditMode) {
            UserService.getDepsToSelect().then((deps) => {
                let depOptions = deps.data.map((x: IAddressBookItem) => ({
                    label: x.name,
                    value: x.key,
                })) as IOption[];
                setDepartmentsList(depOptions);
                setSelectedDep(depOptions.find((x) => x.value == user.departmentId));
            });
        }
    }, [isOrgEditMode]);

    const [showMenu, setShowMenu] = useState<boolean>(false);

    const settingsItem: IListElement = {
        value: 'user-settings',
        label: (
            <div className={'settings-item'} data-testid="settings-item-user-settings">
                <MdSettings size="24" />
                <span>Настройки</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.SETTINGS);
        },
    };
    const usersActivationItem: IListElement = {
        value: 'users-activation',
        label: (
            <div className={'settings-item'} data-testid="settings-item-users-activation">
                <MdGroupAdd size="24" />
                <span>Активация пользователей</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.USERS_ACTIVATION);
        },
    };
    const setMailDeputyItem: IListElement = {
        value: 'mail-deputy',
        label: (
            <div className={'settings-item'} data-testid="settings-item-mail-deputy">
                <MdSupervisorAccount size="24" />
                <span>Замещение сотрудников и уведомления</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.REDIRECTS_AND_REPLACEMENTS);
        },
    };
    const setAssistantSimple: IListElement = {
        value: 'set-assistant',
        label: (
            <div className={'settings-item'} data-testid="settings-item-set-assistant">
                <MdSupervisorAccount size="24" />
                <span>Назначить заместителя</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.SET_ASSISTANT_SIMPLE);
        },
    };
    const viewAccessGroupsItem: IListElement = {
        value: 'roles',
        label: (
            <div className={'settings-item'} data-testid="settings-item-roles">
                <MdGroup size="24" />
                <span>Роли</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.ROLES);
        },
    };
    const selfEditNotificationFilteringItem: IListElement = {
        value: 'notifications-filtering',
        label: (
            <div className={'settings-item'} data-testid="settings-item-notifications-filtering">
                <MdEditNotifications size="24" />
                <span>Настройка уведомлений</span>
            </div>
        ),
        handler: () => {
            navigate(RouteNames.NOTIFICATIONSETTINGS);
        },
    };

    const exitItem: IListElement = {
        value: 'logout',
        label: (
            <div className={clsx('settings-item', 'settings-item--logout')} data-testid="settings-item-logout">
                <MdLogout size="24" />
                <span>Выход из профиля</span>
            </div>
        ),
        handler: async () => {
            const token = localStorage.getItem('frontAccessToken');
            const decoded = jwtDecode(token!);
            if ((decoded as any).signInMethod === 'SAML') {
                navigate(RouteNames.ADFS_SAML_SSO_SIGN_OUT_REDIRECT, {
                    replace: true,
                });
            } else {
                logout();
            }
        },
        separated: true,
        separatedBottom: true,
    };

    const _listMenu: IListElement[] = [];

    if (user.canView.settings) {
        _listMenu.push(settingsItem);
    }

    if (user.canView.usersActivation) {
        _listMenu.push(usersActivationItem);
    }

    if (user.canView.mailDeputy) {
        _listMenu.push(setMailDeputyItem);
    }

    if (user.canView.accessGroups) {
        _listMenu.push(viewAccessGroupsItem);
    }

    if (user.canView.notificationFiltering) {
        _listMenu.push(selfEditNotificationFilteringItem);
    }

    if (user.canView.setAssistantSimple) {
        _listMenu.push(setAssistantSimple);
    }

    if (user.canView.signOut) {
        _listMenu.push(exitItem);
    }

    const onSupportToggle = () => {
        setShowSupportModal(!showSupportModal);
    };

    const getUserMenu = (list: IListElement[]) => {
        return (
            <div className="user-menu-dropdown">
                <UserPanel
                    onOrgChange={() => {
                        setIsOrgEditMode(true);
                    }}
                />
                <List list={list} />
                <VersionPanel />
            </div>
        );
    };

    // -------------------------------------------------------------------------------------------------------------------
    // Отладочные настройки
    // -------------------------------------------------------------------------------------------------------------------

    const [debugSettings, setDebugSettings] = useLocalStorage<IDebugSettings>('Debug-Settings', {
        notifyFormulaErrors: config.environment !== 'PROD',
    });

    useEffect(() => {
        setDebugSettings(debugSettings);
    }, []);

    const onChangeDebugSettings = useCallback(
        (value: IDebugSettings) => {
            setDebugSettings(value);
        },
        [setDebugSettings],
    );

    // -------------------------------------------------------------------------------------------------------------------

    return (
        <div className="rf-navbar">
            <Link to="/" data-testid="navbar-logo">
                <div className="logo-button">
                    <img src={logo} alt="logo" />
                </div>
            </Link>
            <div className="controls">
                <div className="search">
                    <GlobalSearchInput />
                </div>
                <div className="items">
                    {config.environment !== 'PROD' && (
                        <DebugSettings value={debugSettings} onChange={onChangeDebugSettings} />
                    )}
                    {user.canView.documentation && (
                        <Button
                            buttonType="round"
                            textColor="neutral"
                            title="Документация"
                            data-testid="documentation-button"
                            size="m"
                            startAdornment={<MdDescription size="32" />}
                            onClick={() => {
                                navigate(RouteNames.DOCUMENTATION);
                            }}
                        />
                    )}
                    {user.canView.support && (
                        <Button
                            buttonType="round"
                            title="Техническая поддержка"
                            textColor="neutral"
                            data-testid="support-button"
                            size="m"
                            startAdornment={<MdSupportAgent size="32" />}
                            onClick={onSupportToggle}
                        />
                    )}
                    <Button
                        buttonType="round"
                        title="Уведомления"
                        textColor="neutral"
                        data-testid="notifications-button"
                        size="m"
                        startAdornment={<MdNotifications size="32" />}
                        onClick={() => {
                            navigate(RouteNames.NOTIFICATION);
                        }}
                    />
                </div>
                {showSupportModal && <SupportModal onCloseModal={onSupportToggle} />}
                <div className="user">
                    <div>
                        <Menu
                            content={getUserMenu(_listMenu)}
                            offsetValue={{ mainAxis: 6, crossAxis: 0 }}
                            position="bottom-end"
                            onToggleMenu={(show) => {
                                setShowMenu(show);
                            }}
                        >
                            <div className="user-menu">
                                <Button
                                    buttonType="round"
                                    textColor="neutral"
                                    size="m"
                                    className="user-menu__button"
                                    data-testid="user-menu-button"
                                    startAdornment={
                                        userAvatar.photoContent ? (
                                            <Avatar
                                                photo={`data:image/png;base64,${userAvatar.photoContent}`}
                                                firstName={user.firstName}
                                                lastName={user.lastName}
                                                size="s"
                                            />
                                        ) : (
                                            <Avatar icon={() => <MdAccountCircle size="36" />} />
                                        )
                                    }
                                    endAdornment={
                                        showMenu ? (
                                            <MdOutlineExpandLess size={'20px'} />
                                        ) : (
                                            <MdOutlineExpandMore size={'20px'} />
                                        )
                                    }
                                >
                                    {user.name}
                                </Button>
                            </div>
                        </Menu>
                    </div>
                </div>
            </div>

            {isOrgEditMode &&
                (isSavingDepartment ? (
                    <Preloader size="xl" />
                ) : (
                    <Modal
                        size="m"
                        footer={
                            <Button
                                disabled={!selectedDep}
                                onClick={async () => {
                                    if (selectedDep) {
                                        setIsSavingDepartment(true);
                                        await UserService.setUserDepartment(selectedDep.value);
                                        updateUserDepartment(selectedDep.value, selectedDep.label);
                                        setIsOrgEditMode(false);
                                        setIsSavingDepartment(false);
                                    }
                                }}
                            >
                                OK
                            </Button>
                        }
                        header={
                            <div className={clsx('box')}>
                                <div>
                                    <div>Выбор организации</div>
                                </div>
                                <div className={clsx('left')}>
                                    <Button
                                        buttonType="text"
                                        textColor="neutral"
                                        size="xs"
                                        aria-label="Закрыть окно"
                                        onClick={() => {
                                            setIsOrgEditMode(false);
                                        }}
                                        startAdornment={<MdClose size="24" />}
                                    />
                                </div>
                            </div>
                        }
                    >
                        <Select
                            placeholder="Выберите организацию"
                            readOnly
                            values={selectedDep ? [selectedDep] : undefined}
                            options={departmentsList}
                            preloader={departmentsList.length == 0}
                            onChange={(e) => {
                                setSelectedDep(e?.length > 0 ? e[0] : undefined);
                            }}
                        />
                    </Modal>
                ))}
        </div>
    );
};

export default Navbar;
