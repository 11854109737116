import './ApproveListCycle.scss';
import React, { useEffect, useState } from 'react';
import { IDocumentTable } from '@models/Forms/IForms';
import { ICycle, ILevel } from './IApproveListModels';
import ApproveListLevel from './ApproveListLevel';
import clsx from 'clsx';
import { IField } from '@models/IFormData';
import { MdCheckCircle } from 'react-icons/md';
import { FaRegCircleDot } from 'react-icons/fa6';

export interface IApproveListCycleProps {
    cycle: ICycle;
    table: IDocumentTable;
    index: number;
    count: number;
    cellRenderSwitcher: (p: any, column: any, rowParent?: any) => Promise<React.ReactNode>;
    getParentFields: () => IField[];
    docId?: string;
}

const ApproveListCycle: React.FC<IApproveListCycleProps> = ({
    cycle,
    table,
    index,
    count,
    cellRenderSwitcher,
    getParentFields,
    docId,
}: IApproveListCycleProps) => {
    const [expand, setExpand] = useState<boolean>(index === 0);
    const [levels, setLevels] = useState<ILevel[]>([]);
    useEffect(() => {
        setLevels(cycle.levels.reverse());
    }, []);

    return cycle ? (
        <div className="apl-cycle">
            <div
                className="apl-cycle-header"
                onClick={(e) => {
                    setExpand(!expand);
                }}
            >
                <div className="apl-cycle-graph-icon">
                    {index === 0 &&
                    !levels.every((x) =>
                        x.items?.every(
                            (y) =>
                                y['|Статус_исполнения'] != 'В процессе согласования' &&
                                y['|Статус_исполнения'] != 'Выполняется',
                        ),
                    ) ? (
                        <FaRegCircleDot className="apl-icon--Active" size="31" />
                    ) : (
                        <MdCheckCircle className="apl-icon--Done" size="38" />
                    )}
                </div>
                <div className="apl-cycle-title">{cycle.title}</div>
            </div>
            <div className="apl-cycle-box">
                <div
                    className={clsx(
                        'apl-cycle-line',
                        index === 0 ? 'apl-cycle-line--Active' : index !== count - 1 && 'apl-cycle-line--Done',
                    )}
                />
                <div className="apl-cycle-content">
                    {levels.map((level, i) => {
                        return (
                            <ApproveListLevel
                                level={level}
                                table={table}
                                defExpand={expand}
                                key={i}
                                cellRenderSwitcher={cellRenderSwitcher}
                                getParentFields={getParentFields}
                                docId={docId}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default ApproveListCycle;
