import InputNumberControl from '@controls/InputNumberControl';
import React from 'react';
import clsx from 'clsx';
import { IRatingScaleProps } from './IRatingScaleProps';
import { IOption } from '@/types';
import SelectControl from '@controls/SelectControl';

const scaleOptions: IOption[] = [
    { value: 5.0, label: '5' },
    { value: 4.0, label: '4' },
    { value: 3.0, label: '3' },
    { value: 2.0, label: '2' },
];

/**
 * ПИР и СМР (5,4,3,2)
 * @param className
 * @param isEdit
 * @param methods
 * @constructor
 */
export const RatingScalePirSmr = ({ className, isEdit, methods }: IRatingScaleProps) => {
    return (
        <div className={clsx('offer-crit-rating-scale-pir-smr', className)}>
            <SelectControl
                label="Балл"
                name="offerCrit.score"
                readOnly={true}
                required={true}
                rules={{ required: 'Обязательное поле' }}
                disabled={!isEdit}
                highlightMenuItems={false}
                options={scaleOptions}
                formState={methods.formState}
                control={methods.control}
                setValue={(options) => {
                    return options[0].value;
                }}
                getValue={(options: IOption[], value: any) => {
                    return options.filter((x) => x.value === value);
                }}
            />
            <span className={'offer-crit-details__formula-text'}>×</span>
            <InputNumberControl
                label="Вес"
                name="weight"
                required={true}
                readOnly={true}
                disabled={!isEdit}
                floatPoints={4}
                formState={methods.formState}
                control={methods.control}
                rules={{ required: 'Обязательное поле' }}
            />
            <span className={'offer-crit-details__formula-text'}>=</span>
            <InputNumberControl
                label="Рейтинг"
                name="offerCrit.rating"
                required={true}
                readOnly={true}
                disabled={!isEdit}
                floatPoints={4}
                formState={methods.formState}
                control={methods.control}
                rules={{ required: 'Обязательное поле' }}
            />
        </div>
    );
};
